<template>
  <b-container
    class="mb-5"
    fluid
  >
    <b-breadcrumb
      v-if="$route.meta.breadcrumb"
      :items="$route.meta.breadcrumb || []"
    />
    <b-card>
      <b-row class="mb-2">
        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label for="filter_cluster">Filter By Cluster</label>
            <v-select
              id="filter_cluster"
              v-model="selected.filter_cluster"
              :options="list.filter_clusters"
              label="cluster_name"
              :disabled="state.busy"
              placeholder="-- Please Select Cluster --"
            >
              <template #option="{ cluster_name }">
                <span>{{ cluster_name }}</span>
              </template>
              <template #no-options="">
                No Available Clusters
              </template>
            </v-select>
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label for="selectedArea">Filter by Area</label>
            <v-select
              id="selectedArea"
              v-model="selected.filter_area"
              :options="list.filter_areas"
              label="area_name"
              placeholder="-- Please Select Area --"
            >
              <template #option="{ area_name }">
                <strong>{{ area_name }}</strong>
              </template>
              <template #no-options="">
                No Available Areas
              </template>
            </v-select>
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group>
            <label>Search String</label>
            <b-input
              v-model="tableSettings.filter"
              type="text"
              placeholder="Enter search string"
              debounce="1000"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-table
        ref="table"
        hover
        responsive
        class="mt-2"
        :per-page="tableSettings.perPage"
        :current-page="tableSettings.currentPage"
        :items="myProvider"
        :fields="tableSettings.fields"
        :sort-by.sync="tableSettings.sortBy"
        :sort-desc.sync="tableSettings.sortDesc"
        :sort-direction="tableSettings.sortDirection"
        :filter="tableSettings.filter"
        :filter-included-fields="tableSettings.filterOn"
        show-empty
      >
        <template #cell(index)="data">
          {{ tableSettings.currentPage * tableSettings.perPage - tableSettings.perPage + (data.index + 1) }}
        </template>

        <template #cell()="data">
          <div class="text-nowrap">
            {{ data.value }}
          </div>
        </template>

        <template #cell(actions)="row">
          <div class="text-nowrap">
            <b-button
              size="sm"
              class="mr-1"
              @click="onShowDetailForm(row.item)"
            >
              View
            </b-button>
            <b-button
              size="sm"
              variant="success"
              @click="onShowEditForm(row.item)"
            >
              Update
            </b-button>
          </div>
        </template>
      </b-table>

      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <div class="w-100 w-sm-25 mb-2 sm-mb-2">
            <b-select
              v-model="tableSettings.perPage"
              :options="tableSettings.pageOptions"
              size="sm"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="tableSettings.currentPage"
            :total-rows="tableSettings.totalRows"
            :per-page="tableSettings.perPage"
            first-number
            last-number
            pills
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
          />
        </b-col>
      </b-row>
    </b-card>

    <b-modal
      id="modal-view-request-detail"
      title="View Details"
      scrollable
      no-close-on-backdrop
      size="lg"
    >
      <b-tabs>
        <b-tab
          title="Request Details"
        >
          <b-row>
            <b-col
              cols="12"
            >
              <b-form-group>
                <label>Request Date</label>
                <b-input
                  :value="dateTimeFormatter(selected.call_request?.created_at)"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
            >
              <b-form-group>
                <label>Customer Name</label>
                <b-input
                  :value="`${selected.call_request?.customer?.first_name} ${selected.call_request?.customer?.last_name}`"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
            >
              <b-form-group>
                <label>Mobile Number</label>
                <b-input
                  :value="selected.call_request?.customer?.mobile_number"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
            >
              <b-form-group>
                <label>Status</label>
                <b-input
                  :value="selected.call_request?.status"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group>
                <label>Remarks</label>
                <b-textarea
                  :value="selected.call_request?.remarks"
                  rows="10"
                  max-rows="10"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-tab>

        <b-tab
          title="Communication Logs"
        >
          <b-table
            ref="customerLogTable"
            hover
            responsive
            class="mt-2"
            :per-page="tableCallRequestLogSettings.perPage"
            :current-page="tableCallRequestLogSettings.currentPage"
            :items="myCallRequestLogProvider"
            :fields="tableCallRequestLogSettings.fields"
            :sort-by.sync="tableCallRequestLogSettings.sortBy"
            :sort-desc.sync="tableCallRequestLogSettings.sortDesc"
            :sort-direction="tableCallRequestLogSettings.sortDirection"
            :filter="tableCallRequestLogSettings.filter"
            :filter-included-fields="tableCallRequestLogSettings.filterOn"
            show-empty
          >

            <template #cell(index)="data">
              {{ tableCallRequestLogSettings.currentPage * tableCallRequestLogSettings.perPage - tableCallRequestLogSettings.perPage + (data.index + 1) }}
            </template>

            <template #cell()="data">
              <div class="text-nowrap">
                {{ data.value }}
              </div>
            </template>

            <template #cell(actions)="row">
              <div class="text-nowrap">
                <b-button
                  size="sm"
                  @click="onViewCallRequestLog(row.item)"
                >
                  View
                </b-button>
              </div>
            </template>

          </b-table>

          <b-row>
            <b-col
              cols="12"
              sm="6"
            >
              <div class="w-100 w-sm-25 mb-2 sm-mb-2">
                <b-select
                  v-model="tableCallRequestLogSettings.perPage"
                  :options="tableCallRequestLogSettings.pageOptions"
                  size="sm"
                />
              </div>
            </b-col>
            <b-col
              cols="12"
              sm="6"
              class="d-flex justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="tableCallRequestLogSettings.currentPage"
                :total-rows="tableCallRequestLogSettings.totalRows"
                :per-page="tableCallRequestLogSettings.perPage"
                first-number
                last-number
                pills
                prev-text="Prev"
                next-text="Next"
                aria-controls="table"
              />
            </b-col>
          </b-row>
        </b-tab>
      </b-tabs>

      <template #modal-footer="{ cancel }">
        <b-button
          variant="danger"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="modal-view-call-request-log"
      title="View Details"
      size="lg"
      scrollable
      no-close-on-backdrop
    >
      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label>Disposed At</label>
            <b-input
              :value="dateTimeFormatter(selected.log?.created_at)"
              :disabled="true"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label>Agent</label>
            <b-input
              :value="selected.log?.agent_name"
              :disabled="true"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label>Channel</label>
            <b-input
              :value="selected.log?.channel_name"
              :disabled="true"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label>Call Status</label>
            <b-input
              :value="selected.log?.call_status"
              :disabled="true"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
        >
          <b-form-group>
            <label>Unsuccessful Reason</label>
            <b-input
              :value="selected.log?.unsuccessful_reason_name"
              :disabled="true"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label>Disposition Type</label>
            <b-input
              :value="selected.log?.disposition_type_name"
              :disabled="true"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label>Disposition</label>
            <b-input
              :value="selected.log?.disposition_name"
              :disabled="true"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group>
            <label>Remarks</label>
            <b-textarea
              :value="selected.log?.remarks"
              rows="10"
              max-rows="10"
              :disabled="true"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="danger"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="modal-edit-form"
      scrollable
      no-close-on-backdrop
      title="Pending Call"
      size="xl"
      @ok="onConfirmAction"
    >
      <ValidationObserver ref="form">
        <form
          role="form"
          novalidate
        >
          <b-tabs
            class="px-2"
            no-body
          >
            <b-tab
              title="Personal Information"
              active
            >
              <b-row>
                <b-col
                  cols="12"
                  sm="6"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="first Name"
                    vid="first_name"
                    rules="required|max:50"
                  >
                    <b-form-group>
                      <label for="first_name"><strong>First name</strong></label>
                      <b-input
                        id="first_name"
                        v-model="customer.first_name"
                        type="text"
                        :state="errors.length > 0 ? false : null"
                        placeholder="enter first name"
                        autocomplete="off"
                        :disabled="state.busy"
                      />
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                  sm="6"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="last name"
                    vid="last_name"
                    rules="required|max:50"
                  >
                    <b-form-group>
                      <label for="last_name"><strong>Last Name</strong></label>
                      <b-input
                        id="last_name"
                        v-model="customer.last_name"
                        type="text"
                        :state="errors.length > 0 ? false : null"
                        placeholder="enter last name"
                        autocomplete="off"
                        :disabled="state.busy"
                      />
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                  sm="4"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="customer type"
                    vid="customer_type"
                  >
                    <b-form-group>
                      <label for="customer_type"><strong>Customer Type</strong></label>
                      <b-form-select
                        id="customer_type"
                        v-model="customer.customer_type"
                        :options="options.customer_types"
                        :state="errors.length > 0 ? false : null"
                        :disabled="state.busy"
                      >
                        <template #first>
                          <b-form-select-option
                            :value="null"
                            disabled
                          >
                            -- Please Select Customer Type --
                          </b-form-select-option>
                        </template>
                      </b-form-select>
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                  sm="4"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="mobile number"
                    vid="mobile_number"
                    rules="required|min:11|max:11"
                  >
                    <b-form-group>
                      <label for="mobile_number"><strong>Mobile Number</strong></label>
                      <b-input
                        id="mobile_number"
                        v-model="customer.mobile_number"
                        type="text"
                        :state="errors.length > 0 ? false : null"
                        placeholder="enter contact number"
                        autocomplete="off"
                        maxlength="11"
                        :disabled="true"
                      />
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                  sm="4"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="email"
                    vid="email"
                    rules="email|max:100"
                  >
                    <b-form-group>
                      <label for="email"><strong>Email</strong></label>
                      <b-input
                        id="email"
                        v-model="customer.email"
                        type="email"
                        :state="errors.length > 0 ? false : null"
                        placeholder="enter email"
                        autocomplete="off"
                        :disabled="state.busy"
                      />
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                  sm="4"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="birth date"
                    vid="birth_date"
                  >
                    <b-form-group>
                      <label for="birth_date"><strong>Birth Date</strong></label>
                      <b-input
                        id="birth_date"
                        v-model="customer.birth_date"
                        type="date"
                        placeholder="enter birth date"
                        :state="errors.length > 0 ? false : null"
                        :disabled="state.busy"
                      />
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                  sm="4"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="gender"
                    vid="gender"
                  >
                    <b-form-group>
                      <label for="gender"><strong>Gender</strong></label>
                      <b-form-select
                        id="gender"
                        v-model="customer.gender"
                        :options="options.genders"
                        :state="errors.length > 0 ? false : null"
                        :disabled="state.busy"
                      >
                        <template #first>
                          <b-form-select-option
                            :value="null"
                            disabled
                          >
                            -- Please Select Gender --
                          </b-form-select-option>
                        </template>
                      </b-form-select>
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                  sm="4"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="experience"
                    vid="experience"
                    rules="required|numeric"
                  >
                    <b-form-group>
                      <label for="experience"><strong>Years in Farming</strong></label>
                      <b-input
                        id="experience"
                        v-model="customer.experience"
                        type="text"
                        placeholder="enter years in farming"
                        autocomplete="off"
                        :state="errors.length > 0 ? false : null"
                        :disabled="state.busy"
                      />
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                  sm="4"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="primary dialect"
                    vid="primary_dialect"
                    rules=""
                  >
                    <b-form-group>
                      <label for="primary_dialect"><strong>Primary Dialect</strong></label>
                      <b-form-select
                        id="primary_dialect"
                        v-model="customer.primary_dialect"
                        :options="list.dialects"
                        :state="errors.length > 0 ? false : null"
                        :disabled="state.busy"
                      >
                        <template #first>
                          <b-form-select-option
                            :value="null"
                            disabled
                          >
                            -- Please Select Primary Dialect --
                          </b-form-select-option>
                        </template>
                      </b-form-select>
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                  sm="4"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="secondary dialect"
                    vid="secondary_dialect"
                    rules=""
                  >
                    <b-form-group>
                      <label for="secondary_dialect"><strong>Secondary Dialect</strong></label>
                      <b-form-select
                        id="secondary_dialect"
                        v-model="customer.secondary_dialect"
                        :options="list.dialects"
                        :state="errors.length > 0 ? false : null"
                        :disabled="state.busy"
                      >
                        <template #first>
                          <b-form-select-option
                            :value="null"
                            disabled
                          >
                            -- Please Select Secondary Dialect --
                          </b-form-select-option>
                        </template>
                      </b-form-select>
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                  sm="4"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="number of household"
                    vid="number_of_household"
                    rules="required|numeric"
                  >
                    <b-form-group>
                      <label for="number_of_household"><strong>Number Of Household</strong></label>
                      <b-input
                        id="number_of_household"
                        v-model="customer.number_of_household"
                        type="text"
                        placeholder="enter number of house hold"
                        autocomplete="off"
                        :state="errors.length > 0 ? false : null"
                        :disabled="state.busy"
                      />
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                  sm="6"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="occupation"
                    vid="occupation"
                    rules="max:100"
                  >
                    <b-form-group>
                      <label for="occupation"><strong>Occupation</strong></label>
                      <b-input
                        id="occupation"
                        v-model="customer.occupation"
                        type="text"
                        :state="errors.length > 0 ? false : null"
                        placeholder="enter occupation"
                        autocomplete="off"
                        :disabled="state.busy"
                      />
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                  sm="6"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="other occupation"
                    vid="other_occupation"
                    rules="max:100"
                  >
                    <b-form-group>
                      <label for="other_occupation"><strong>Other Occupation</strong></label>
                      <b-input
                        id="other_occupation"
                        v-model="customer.other_occupation"
                        type="text"
                        :state="errors.length > 0 ? false : null"
                        placeholder="enter other occupation"
                        autocomplete="off"
                        :disabled="state.busy"
                      />
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="dealers"
                    vid="dealers"
                    rules=""
                  >
                    <b-form-group>
                      <label for="dealers"><strong>Branch / Dealer</strong></label>
                      <v-select
                        id="dealers"
                        v-model="customer.dealers"
                        :options="list.dealers"
                        :class="`${errors.length > 0 ? 'is-invalid' : ''}`"
                        label="business_name"
                        :disabled="state.busy"
                        multiple
                      >
                        <template #option="{ business_name }">
                          <strong>{{ business_name }}</strong>
                        </template>
                        <template #no-options="">
                          No Available Dealers
                        </template>
                      </v-select>
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="other dealer"
                    vid="other_dealer"
                    rules="max:100"
                  >
                    <b-form-group>
                      <label for="other_dealer"><strong>Other Dealer</strong></label>
                      <b-input
                        id="other_dealer"
                        v-model="customer.other_dealer"
                        type="text"
                        :state="errors.length > 0 ? false : null"
                        placeholder="enter other dealer"
                        autocomplete="off"
                        :disabled="state.busy"
                      />
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                  sm="6"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="cluster"
                    vid="cluster"
                    rules=""
                  >
                    <b-form-group>
                      <label for="cluster"><strong>Cluster</strong></label>
                      <v-select
                        id="cluster"
                        v-model="selected.cluster"
                        :options="list.clusters"
                        :class="`${errors.length > 0 ? 'is-invalid' : ''}`"
                        label="cluster_name"
                        :disabled="state.busy"
                      >
                        <template #option="{ cluster_name }">
                          <strong>{{ cluster_name }}</strong>
                        </template>
                        <template #no-options="">
                          No Available Clusters
                        </template>
                      </v-select>
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                  sm="6"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="areas"
                    vid="areas"
                    rules=""
                  >
                    <b-form-group>
                      <label for="areas"><strong>Area</strong></label>
                      <v-select
                        id="areas"
                        v-model="selected.area"
                        :options="list.areas"
                        :class="`${errors.length > 0 ? 'is-invalid' : ''}`"
                        label="area_name"
                        :disabled="state.busy"
                      >
                        <template #option="{ area_name }">
                          <strong>{{ area_name }}</strong>
                        </template>
                        <template #no-options="">
                          No Available Clusters
                        </template>
                      </v-select>
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="street name"
                    vid="street_name"
                    rules="max:100"
                  >
                    <b-form-group>
                      <label for="street_name"><strong>Street Name</strong></label>
                      <b-input
                        id="street_name"
                        v-model="customer.street_name"
                        type="text"
                        :state="errors.length > 0 ? false : null"
                        placeholder="enter street name"
                        autocomplete="off"
                        :disabled="state.busy"
                      />
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                  sm="4"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="province"
                    vid="province"
                    rules=""
                  >
                    <b-form-group>
                      <label for="province"><strong>Province</strong></label>
                      <v-select
                        id="province"
                        v-model="selected.province"
                        :options="list.provinces"
                        :class="`${errors.length > 0 ? 'is-invalid' : ''}`"
                        label="province_name"
                        :disabled="state.busy"
                      >
                        <template #option="{ province_name }">
                          <strong>{{ province_name }}</strong>
                        </template>
                        <template #no-options="">
                          No Available Provinces
                        </template>
                      </v-select>
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                  sm="4"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="municipality"
                    vid="municipality"
                    rules=""
                  >
                    <b-form-group>
                      <label for="municipality"><strong>Municipality</strong></label>
                      <v-select
                        id="municipality"
                        v-model="selected.municipality"
                        :options="list.municipalities"
                        :class="`${errors.length > 0 ? 'is-invalid' : ''}`"
                        label="municipality_name"
                        :disabled="state.busy"
                      >
                        <template #option="{ municipality_name }">
                          <strong>{{ municipality_name }}</strong>
                        </template>
                        <template #no-options="">
                          No Available Municipalities
                        </template>
                      </v-select>
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                  sm="4"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="barangay"
                    vid="barangay"
                    rules=""
                  >
                    <b-form-group>
                      <label for="barangay"><strong>Barangay</strong></label>
                      <v-select
                        id="arangay"
                        v-model="selected.barangay"
                        :options="list.barangays"
                        :class="`${errors.length > 0 ? 'is-invalid' : ''}`"
                        label="barangay_name"
                        :disabled="state.busy"
                      >
                        <template #option="{ barangay_name }">
                          <strong>{{ barangay_name }}</strong>
                        </template>
                        <template #no-options="">
                          No Available Barangays
                        </template>
                      </v-select>
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="farm address"
                    vid="farm_address"
                    rules="max:500"
                  >
                    <b-form-group>
                      <label for="farm_address"><strong>Farm Address</strong></label>
                      <b-textarea
                        id="farm_address"
                        v-model="customer.farm_address"
                        :state="errors.length > 0 ? false : null"
                        placeholder="enter farm address"
                        autocomplete="off"
                        rows="6"
                        max-rows="6"
                        :disabled="state.busy"
                      />
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                  sm="4"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="mode of payment"
                    vid="mop"
                    rules=""
                  >
                    <b-form-group>
                      <label for="mode_of_payment"><strong>Mode of Payment</strong></label>
                      <b-form-select
                        id="mode_of_payment"
                        v-model="customer.mop"
                        :options="list.mode_of_payments"
                        :state="errors.length > 0 ? false : null"
                        :disabled="state.busy"
                      >
                        <template #first>
                          <b-form-select-option
                            :value="null"
                            disabled
                          >
                            -- Please Select Mode of Payment --
                          </b-form-select-option>
                        </template>
                      </b-form-select>
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                  sm="4"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="Mode of Communication"
                    vid="moc"
                    rules=""
                  >
                    <b-form-group>
                      <label for="mode_of_communication"><strong>Mode of Communication</strong></label>
                      <b-form-select
                        id="mode_of_communication"
                        v-model="customer.moc"
                        :options="list.mode_of_communications"
                        :state="errors.length > 0 ? false : null"
                        :disabled="state.busy"
                      >
                        <template #first>
                          <b-form-select-option
                            :value="null"
                            disabled
                          >
                            -- Please Select Mode of Communication --
                          </b-form-select-option>
                        </template>
                      </b-form-select>
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                  sm="4"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="preferred time"
                    vid="preferred_time"
                    rules=""
                  >
                    <b-form-group>
                      <label for="preferred_time"><strong>Preferred Time</strong></label>
                      <b-form-select
                        id="preferred_time"
                        v-model="customer.preferred_time"
                        :options="options.schedules"
                        :disabled="state.busy"
                      >
                        <template #first>
                          <b-form-select-option
                            :value="null"
                            disabled
                          >
                            -- Please Select Preferred Time --
                          </b-form-select-option>
                        </template>
                      </b-form-select>
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                  class="mb-5"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="raisers"
                    vid="raisers"
                    rules=""
                  >
                    <b-form-group>
                      <label for="raisers"><strong>Raisers</strong></label>
                      <v-select
                        id="raisers"
                        v-model="customer.raisers"
                        :options="list.raisers"
                        :class="`${errors.length > 0 ? 'is-invalid' : ''}`"
                        label="raiser_name"
                        :disabled="state.busy"
                        multiple
                      >
                        <template #option="{ raiser_name }">
                          <strong>{{ raiser_name }}</strong>
                        </template>
                        <template #no-options="">
                          No Available Raisers
                        </template>
                      </v-select>
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

              </b-row>
            </b-tab>

            <b-tab title="Custom Fields">
              <b-row>
                <b-col
                  cols="12"
                >
                  <b-form-group>
                    <label for="field"><strong>Custom Field</strong></label>
                    <b-form-select
                      id="field"
                      v-model="customField.field"
                      :options="list.custom_fields"
                    >
                      <template #first>
                        <b-form-select-option
                          :value="null"
                          disabled
                        >
                          -- Please Select Custom Field --
                        </b-form-select-option>
                      </template>
                    </b-form-select>
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                >
                  <b-form-group>
                    <label for="field_value"><strong>Custom Value</strong></label>
                    <b-textarea
                      id="field_value"
                      v-model="customField.field_value"
                      rows="6"
                      max-rows="6"
                      placeholder="enter value"
                      autocomplete="off"
                      :disabled="customField.field === null"
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  class="mb-3"
                >
                  <b-button
                    type="button"
                    variant="success"
                    class="mr-2"
                    :disabled="customFieldInserButttonDisabledState"
                    @click="onCustomFieldInsert"
                  >
                    Insert Field
                  </b-button>

                  <b-button
                    type="button"
                    variant="secondary"
                    @click="onCustomFieldRefresh"
                  >
                    Refresh List
                  </b-button>
                </b-col>

                <b-col
                  v-for="(field, key) in customer.custom_fields"
                  :key="key"
                  cols="12"
                  sm="6"
                >
                  <b-form-group>
                    <label :for="field.field_label"><strong>{{ field.field_label }}</strong></label>
                    <b-textarea
                      :id="field.field_label"
                      v-model="field.field_value"
                      rows="6"
                      max-rows="6"
                      autocomplete="off"
                      :disabled="true"
                    />
                    <a
                      v-if="isNaN(parseInt(field.id))"
                      href="javascript:void(0);"
                      class="text-danger"
                      @click="onCustomFieldRemove(field)"
                    >Remove Field</a>
                  </b-form-group>
                </b-col>

              </b-row>
            </b-tab>

            <b-tab title="Crop Details">
              <b-row>
                <b-col
                  cols="12"
                  sm="4"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="Crop"
                    vid="crop"
                  >
                    <b-form-group>
                      <label for="crop"><strong>Crop</strong></label>
                      <b-form-select
                        id="crop"
                        v-model="cropDetail.crop"
                        :options="list.crops"
                        :state="errors.length > 0 ? false : null"
                      >
                        <template #first>
                          <b-form-select-option
                            :value="null"
                            disabled
                          >
                            -- Please Select Crop --
                          </b-form-select-option>
                        </template>
                      </b-form-select>
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                  sm="4"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="Field Size"
                    vid="field_size"
                  >
                    <b-form-group>
                      <label for="field_size"><strong>Field Size</strong></label>
                      <b-form-select
                        id="field_size"
                        v-model="cropDetail.field_size"
                        :options="list.field_sizes"
                        :state="errors.length > 0 ? false : null"
                      >
                        <template #first>
                          <b-form-select-option
                            :value="null"
                            disabled
                          >
                            -- Please Select Field Size --
                          </b-form-select-option>
                        </template>
                      </b-form-select>
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                  sm="4"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="Number of Hectares"
                    vid="number_of_hectares"
                    rules="double|max_value:200"
                  >
                    <b-form-group>
                      <label for="number_of_hectares"><strong>Number of Hectares</strong></label>
                      <b-input
                        id="number_of_hectares"
                        v-model="cropDetail.number_of_hectares"
                        type="number"
                        :state="errors.length > 0 ? false : null"
                        placeholder="enter number of hectares"
                        autocomplete="off"
                      />
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col cols="12">
                  <b-button
                    class="mr-2"
                    type="button"
                    :variant="cropDetailButtonVariant"
                    :disabled="cropDetailButtonDisabledState"
                    @click="onCropDetailButtonClick"
                  >
                    {{ cropDetailButtonLabel }}
                  </b-button>
                  <b-button
                    v-if="state.cropDetailEditing"
                    type="button"
                    variant="danger"
                    @click="onCropDetailCancelUpdate"
                  >
                    Cancel Update
                  </b-button>
                </b-col>

                <b-col
                  cols="12"
                  class="mt-2"
                >
                  <b-table
                    ref="table-crop-details"
                    hover
                    responsive
                    show-empty
                    :fields="tableCropDetails.fields"
                    :items="filteredCustomerCropDetails"
                  >
                    <template #cell(index)="data">
                      {{ tableCropDetails.currentPage * tableCropDetails.perPage - tableCropDetails.perPage + (data.index + 1) }}
                    </template>

                    <template #cell()="data">
                      <div class="text-nowrap">
                        {{ data.value }}
                      </div>
                    </template>

                    <template #cell(actions)="row">
                      <div class="text-nowrap">
                        <b-button
                          size="sm"
                          variant="success"
                          class="mr-2"
                          :disabled="state.cropDetailEditing"
                          @click="onCropDetailEdit(row.item)"
                        >
                          Edit
                        </b-button>

                        <b-button
                          v-if="isNaN(parseInt(row.item.id))"
                          size="sm"
                          variant="danger"
                          :disabled="state.cropDetailEditing"
                          @click="onCropDetailRemove(row.item)"
                        >
                          Remove
                        </b-button>
                      </div>
                    </template>
                  </b-table>
                </b-col>
              </b-row>
            </b-tab>

            <b-tab title="Crop Histories">
              <b-row>
                <b-col
                  cols="12"
                  sm="6"
                >

                  <b-form-group>
                    <label for="cropping_season"><strong>Cropping Season</strong></label>
                    <v-select
                      id="cropping_season"
                      v-model="selected2.cropping_season"
                      :options="list.cropping_seasons"
                      label="text"
                      placeholder="-- Please Select Cropping Season --"
                    >
                      <template #option="{ text }">
                        <span>{{ text }}</span>
                      </template>
                      <template #no-options="">
                        No Available Cropping Seasons
                      </template>
                    </v-select>
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  sm="6"
                >

                  <b-form-group>
                    <label for="cropping_year"><strong>Cropping Year</strong></label>
                    <v-select
                      id="cropping_year"
                      v-model="selected2.cropping_year"
                      :options="list.cropping_years"
                      label="text"
                      placeholder="-- Please Select Cropping Year --"
                    >
                      <template #option="{ text }">
                        <span>{{ text }}</span>
                      </template>
                      <template #no-options="">
                        No Available Cropping Years
                      </template>
                    </v-select>
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  sm="6"
                >
                  <b-form-group>
                    <label for="crop"><strong>Crop</strong></label>
                    <v-select
                      id="crop"
                      v-model="selected2.crop"
                      :options="list.crops"
                      label="text"
                      placeholder="-- Please Select Crop --"
                    >
                      <template #option="{ text }">
                        <span>{{ text }}</span>
                      </template>
                      <template #no-options="">
                        No Available Crops
                      </template>
                    </v-select>
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  sm="6"
                >

                  <b-form-group>
                    <label for="classification"><strong>Crop Classification</strong></label>
                    <v-select
                      id="classification"
                      v-model="selected2.classification"
                      :options="list.classifications"
                      label="classification_name"
                      :disabled="selected2.crop === null"
                      placeholder="-- Please Select Crop Classification --"
                    >
                      <template #option="{ classification_name }">
                        <span>{{ classification_name }}</span>
                      </template>
                      <template #no-options="">
                        No Available Crop Classifications
                      </template>
                    </v-select>
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  sm="6"
                >
                  <b-form-group>
                    <label for="varieties"><strong>Crop Varieties</strong></label>
                    <v-select
                      id="varieties"
                      v-model="selected2.varieties"
                      :options="list.varieties"
                      label="variety_name"
                      :disabled="selected2.crop === null"
                      multiple
                      placeholder="-- Please Select Crop Varieties --"
                    >
                      <template #option="{ variety_name }">
                        <span>{{ variety_name }}</span>
                      </template>
                      <template #no-options="">
                        No Available Crop Varieties
                      </template>
                    </v-select>
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  sm="6"
                >
                  <b-form-group>
                    <label for="herbicide_ones"><strong>Crop Herbicides</strong></label>
                    <v-select
                      id="herbicide_ones"
                      v-model="selected2.herbicide_ones"
                      :options="list.herbicides"
                      label="herbicide_name"
                      :disabled="selected2.crop === null"
                      multiple
                      placeholder="-- Please Select Crop Herbicides --"
                    >
                      <template #option="{ herbicide_name }">
                        <span>{{ herbicide_name }}</span>
                      </template>
                      <template #no-options="">
                        No Available Crop Herbicides
                      </template>
                    </v-select>
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  sm="6"
                >
                  <b-form-group>
                    <label for="herbicide_twos"><strong>Crop Herbicides (Dike)</strong></label>
                    <v-select
                      id="herbicide_twos"
                      v-model="selected2.herbicide_twos"
                      :options="list.herbicides"
                      label="herbicide_name"
                      :disabled="selected2.crop === null"
                      multiple
                      placeholder="-- Please Select Crop Herbicides --"
                    >
                      <template #option="{ herbicide_name }">
                        <span>{{ herbicide_name }}</span>
                      </template>
                      <template #no-options="">
                        No Available Crop Herbicides
                      </template>
                    </v-select>
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  sm="6"
                >
                  <b-form-group>
                    <label for="fungicides"><strong>Crop Fungicides</strong></label>
                    <v-select
                      id="fungicides"
                      v-model="selected2.fungicides"
                      :options="list.fungicides"
                      label="fungicide_name"
                      :disabled="selected2.crop === null"
                      multiple
                      placeholder="-- Please Select Crop Fungicides --"
                    >
                      <template #option="{ fungicide_name }">
                        <span>{{ fungicide_name }}</span>
                      </template>
                      <template #no-options="">
                        No Available Crop Fungicides
                      </template>
                    </v-select>
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  sm="6"
                >
                  <b-form-group>
                    <label for="insecticides"><strong>Crop Insecticides</strong></label>
                    <v-select
                      id="insecticides"
                      v-model="selected2.insecticides"
                      :options="list.insecticides"
                      label="insecticide_name"
                      :disabled="selected2.crop === null"
                      multiple
                      placeholder="-- Please Select Crop Insecticides --"
                    >
                      <template #option="{ insecticide_name }">
                        <span>{{ insecticide_name }}</span>
                      </template>
                      <template #no-options="">
                        No Available Crop Insecticides
                      </template>
                    </v-select>
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  sm="6"
                >
                  <b-form-group>
                    <label for="fertilizers"><strong>Crop Fertilizers</strong></label>
                    <v-select
                      id="fertilizers"
                      v-model="selected2.fertilizers"
                      :options="list.fertilizers"
                      label="fertilizer_name"
                      :disabled="selected2.crop === null"
                      multiple
                      placeholder="-- Please Select Crop Fertilizers --"
                    >
                      <template #option="{ fertilizer_name }">
                        <span>{{ fertilizer_name }}</span>
                      </template>
                      <template #no-options="">
                        No Available Crop Fertilizers
                      </template>
                    </v-select>
                  </b-form-group>
                </b-col>

                <b-col cols="12">
                  <b-button
                    class="mr-2"
                    type="button"
                    :variant="cropHistoryButtonVariant"
                    :disabled="cropHistoryButtonDisabledState"
                    @click="onCropHistoryButtonClick"
                  >
                    {{ cropHistoryButtonLabel }}
                  </b-button>
                  <b-button
                    v-if="state.cropHistoryEditing"
                    type="button"
                    variant="danger"
                    @click="onCropHistoryCancelUpdate"
                  >
                    Cancel Update
                  </b-button>
                </b-col>

                <b-col
                  cols="12"
                  class="mt-2"
                >
                  <b-table
                    ref="table-crop-history"
                    class="mt-5"
                    hover
                    responsive
                    show-empty
                    :fields="tableCropHistories.fields"
                    :items="filteredCustomerCropHistories"
                  >
                    <template #cell(index)="data">
                      {{ tableCropHistories.currentPage * tableCropHistories.perPage - tableCropHistories.perPage + (data.index + 1) }}
                    </template>

                    <template #cell()="data">
                      <div class="text-nowrap">
                        {{ data.value }}
                      </div>
                    </template>

                    <template #cell(actions)="row">
                      <div class="text-nowrap">
                        <b-button
                          size="sm"
                          variant="success"
                          :disabled="state.cropHistoryEditing"
                          class="mr-2"
                          @click="onCropHistoryEdit(row.item)"
                        >
                          Edit
                        </b-button>

                        <b-button
                          v-if="isNaN(parseInt(row.item.id))"
                          size="sm"
                          variant="danger"
                          :disabled="state.cropHistoryEditing"
                          @click="onCropHistoryRemove(row.item)"
                        >
                          Remove
                        </b-button>
                      </div>
                    </template>
                  </b-table>
                </b-col>

              </b-row>
            </b-tab>

            <b-tab title="Crop Prices">
              <b-row>
                <b-col
                  cols="12"
                  sm="6"
                >
                  <b-form-group>
                    <label for="cropping_season"><strong>Cropping Season</strong></label>
                    <b-form-select
                      id="cropping_season"
                      v-model="cropPrice.cropping_season"
                      :options="list.cropping_seasons"
                    >
                      <template #first>
                        <b-form-select-option
                          :value="null"
                          disabled
                        >
                          -- Please Select Cropping Season --
                        </b-form-select-option>
                      </template>
                    </b-form-select>
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  sm="6"
                >
                  <b-form-group>
                    <label for="cropping_year"><strong>Cropping Year</strong></label>
                    <b-form-select
                      id="cropping_year"
                      v-model="cropPrice.cropping_year"
                      :options="list.cropping_years"
                    >
                      <template #first>
                        <b-form-select-option
                          :value="null"
                          disabled
                        >
                          -- Please Select Cropping Year --
                        </b-form-select-option>
                      </template>
                    </b-form-select>
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  sm="6"
                >
                  <b-form-group>
                    <label for="crop"><strong>Crop</strong></label>
                    <b-form-select
                      id="crop"
                      v-model="cropPrice.crop"
                      :options="list.crops"
                    >
                      <template #first>
                        <b-form-select-option
                          :value="null"
                          disabled
                        >
                          -- Please Select Crop --
                        </b-form-select-option>
                      </template>
                    </b-form-select>
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  sm="6"
                >
                  <b-form-group>
                    <label for="selling_condition"><strong>Selling Condition</strong></label>
                    <b-form-select
                      id="selling_condition"
                      v-model="cropPrice.selling_condition"
                      :options="list.selling_conditions"
                    >
                      <template #first>
                        <b-form-select-option
                          :value="null"
                          disabled
                        >
                          -- Please Select Selling State --
                        </b-form-select-option>
                      </template>
                    </b-form-select>
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  sm="6"
                >
                  <b-form-group>
                    <label for="total_harvested"><strong>Total Harvested (Bags / Sacks)</strong></label>
                    <b-input
                      id="total_harvested"
                      v-model="cropPrice.total_harvested"
                      type="number"
                      placeholder="enter total harvested"
                      autocomplete="off"
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  sm="6"
                >
                  <b-form-group>
                    <label for="price"><strong>Selling Price</strong></label>
                    <b-input
                      id="price"
                      v-model="cropPrice.selling_price"
                      type="number"
                      placeholder="enter selling price"
                      autocomplete="off"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12">
                  <b-button
                    class="mr-2"
                    type="button"
                    :variant="cropPriceButtonVariant"
                    :disabled="cropPriceButtonDisabledState"
                    @click="onCropPriceButtonClick"
                  >
                    {{ cropPriceButtonLabel }}
                  </b-button>
                  <b-button
                    v-if="state.cropPriceEditing"
                    type="button"
                    variant="danger"
                    @click="onCropPriceCancelUpdate"
                  >
                    Cancel Update
                  </b-button>
                </b-col>

                <b-col
                  cols="12"
                  class="mt-2"
                >
                  <b-table
                    ref="table-crop-prices"
                    hover
                    responsive
                    show-empty
                    :fields="tableCropPrices.fields"
                    :items="filteredCustomerCropPrices"
                  >
                    <template #cell(index)="data">
                      {{ tableCropPrices.currentPage * tableCropPrices.perPage - tableCropPrices.perPage + (data.index + 1) }}
                    </template>

                    <template #cell()="data">
                      <div class="text-nowrap">
                        {{ data.value }}
                      </div>
                    </template>

                    <template #cell(actions)="row">
                      <div class="text-nowrap">
                        <b-button
                          size="sm"
                          variant="success"
                          :disabled="state.cropPriceEditing"
                          class="mr-2"
                          @click="onCropPriceEdit(row.item)"
                        >
                          Edit
                        </b-button>

                        <b-button
                          v-if="isNaN(parseInt(row.item.id))"
                          size="sm"
                          variant="danger"
                          :disabled="state.cropPriceEditing"
                          @click="onCropPriceRemove(row.item)"
                        >
                          Remove
                        </b-button>
                      </div>
                    </template>
                  </b-table>
                </b-col>

              </b-row>
            </b-tab>

            <b-tab
              title="Visitation Logs"
            >
              <b-table
                ref="visitationLogTable"
                hover
                responsive
                class="mt-2"
                :per-page="tableVisitationLogSettings.perPage"
                :current-page="tableVisitationLogSettings.currentPage"
                :items="myVisitationLogProvider"
                :fields="tableVisitationLogSettings.fields"
                :sort-by.sync="tableVisitationLogSettings.sortBy"
                :sort-desc.sync="tableVisitationLogSettings.sortDesc"
                :sort-direction="tableVisitationLogSettings.sortDirection"
                :filter="tableVisitationLogSettings.filter"
                :filter-included-fields="tableVisitationLogSettings.filterOn"
                show-empty
              >

                <template #cell(index)="data">
                  {{ tableVisitationLogSettings.currentPage * tableVisitationLogSettings.perPage - tableVisitationLogSettings.perPage + (data.index + 1) }}
                </template>

                <template #cell()="data">
                  <div class="text-nowrap">
                    {{ data.value }}
                  </div>
                </template>

                <template #cell(actions)="row">
                  <div class="text-nowrap">
                    <b-button
                      size="sm"
                      @click="onShowLogDetail(row.item)"
                    >
                      View
                    </b-button>
                  </div>
                </template>

              </b-table>

              <b-row>
                <b-col
                  cols="12"
                  sm="6"
                >
                  <div class="w-100 w-sm-25 mb-2 sm-mb-2">
                    <b-select
                      v-model="tableVisitationLogSettings.perPage"
                      :options="tableVisitationLogSettings.pageOptions"
                      size="sm"
                    />
                  </div>
                </b-col>
                <b-col
                  cols="12"
                  sm="6"
                  class="d-flex justify-content-center justify-content-sm-end"
                >
                  <b-pagination
                    v-model="tableVisitationLogSettings.currentPage"
                    :total-rows="tableVisitationLogSettings.totalRows"
                    :per-page="tableVisitationLogSettings.perPage"
                    first-number
                    last-number
                    pills
                    prev-text="Prev"
                    next-text="Next"
                    aria-controls="table"
                  />
                </b-col>
              </b-row>
            </b-tab>

            <b-tab title="Marketing Programs">
              <b-row>
                <b-col
                  cols="12"
                  class="mt-2"
                >
                  <b-table
                    ref="table-marketing-programs"
                    hover
                    responsive
                    show-empty
                    :fields="tableMarketingPrograms.fields"
                    :items="filteredCustomerMarketingPrograms"
                  >
                    <template #cell(index)="data">
                      {{ tableMarketingPrograms.currentPage * tableMarketingPrograms.perPage - tableMarketingPrograms.perPage + (data.index + 1) }}
                    </template>

                    <template #cell()="data">
                      <div class="text-nowrap">
                        {{ data.value }}
                      </div>
                    </template>
                  </b-table>
                </b-col>
              </b-row>
            </b-tab>

            <b-tab
              title="Call Disposition"
            >
              <b-row>
                <b-col cols="12">
                  <b-table
                    ref="tableChannelDisposition"
                    hover
                    responsive
                    class="mt-2"
                    :per-page="tableCommunicationLogSettings.perPage"
                    :current-page="tableCommunicationLogSettings.currentPage"
                    :items="myLogProvider"
                    :fields="tableCommunicationLogSettings.fields"
                    :sort-by.sync="tableCommunicationLogSettings.sortBy"
                    :sort-desc.sync="tableCommunicationLogSettings.sortDesc"
                    :sort-direction="tableCommunicationLogSettings.sortDirection"
                    :filter="tableCommunicationLogSettings.filter"
                    :filter-included-fields="tableCommunicationLogSettings.filterOn"
                    show-empty
                  >
                    <template #cell(index)="data">
                      {{ tableCommunicationLogSettings.currentPage * tableCommunicationLogSettings.perPage - tableCommunicationLogSettings.perPage + (data.index + 1) }}
                    </template>

                    <template #cell()="data">
                      <div class="text-nowrap">
                        {{ data.value }}
                      </div>
                    </template>

                    <template #cell(actions)="row">
                      <div class="text-nowrap">
                        <b-button
                          size="sm"
                          variant="secondary"
                          class="mr-2"
                          @click="onViewCommunicationLogDetail(row.item)"
                        >
                          View
                        </b-button>
                      </div>
                    </template>

                  </b-table>

                  <b-row>
                    <b-col
                      cols="12"
                      sm="6"
                    >
                      <div class="w-100 w-sm-25 mb-2 sm-mb-2">
                        <b-select
                          v-model="tableCommunicationLogSettings.perPage"
                          :options="tableCommunicationLogSettings.pageOptions"
                          size="sm"
                        />
                      </div>
                    </b-col>
                    <b-col
                      cols="12"
                      sm="6"
                      class="d-flex justify-content-center justify-content-sm-end"
                    >
                      <b-pagination
                        v-model="tableCommunicationLogSettings.currentPage"
                        :total-rows="tableCommunicationLogSettings.totalRows"
                        :per-page="tableCommunicationLogSettings.perPage"
                        first-number
                        last-number
                        pills
                        prev-text="Prev"
                        next-text="Next"
                        aria-controls="table"
                      />
                    </b-col>
                  </b-row>
                </b-col>

                <b-col cols="12">
                  <b-form-group>
                    <label><strong>Previous Remarks</strong></label>
                    <b-textarea
                      :value="allComments"
                      rows="10"
                      max-rows="10"
                      :disabled="true"
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  sm="6"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="call status"
                    vid="call_status"
                    rules="required"
                  >
                    <b-form-group>
                      <label for="call_status"><strong>Call Status</strong></label>
                      <v-select
                        id="call_status"
                        v-model="selected.call_status"
                        :options="options.call_statuses"
                        :class="`${errors.length > 0 ? 'is-invalid' : ''}`"
                        label="text"
                        :disabled="state.busy"
                        placeholder="-- Please Select Call Status --"
                      >
                        <template #option="{ text }">
                          <span>{{ text }}</span>
                        </template>
                        <template #no-options="">
                          No Available Call Statuses
                        </template>
                      </v-select>
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                  sm="6"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="unsuccessful reason"
                    vid="unsuccessful_reason"
                    :rules="rulesUnsuccessfulReason"
                  >
                    <b-form-group>
                      <label for="unsuccessful_reason"><strong>Unsuccessful Reason</strong></label>
                      <v-select
                        id="unsuccessful_reason"
                        v-model="selected.unsuccessful_reason"
                        :options="list.unsuccessful_reasons"
                        :class="`${errors.length > 0 ? 'is-invalid' : ''}`"
                        label="reason_name"
                        :disabled="(state.busy || customer.call_status !== 'Unsuccessful')"
                        placeholder="-- Please Select Unsuccessful Reason --"
                      >
                        <template #option="{ reason_name }">
                          <span>{{ reason_name }}</span>
                        </template>
                        <template #no-options="">
                          No Available Unsuccessful Reasons
                        </template>
                      </v-select>
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                  sm="6"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="disposition type"
                    vid="disposition_type"
                    :rules="rulesDispositionType"
                  >
                    <b-form-group>
                      <label for="disposition_type"><strong>Disposition Type</strong></label>
                      <v-select
                        id="disposition_type"
                        v-model="selected.disposition_type"
                        :options="list.disposition_types"
                        :class="`${errors.length > 0 ? 'is-invalid' : ''}`"
                        label="type_name"
                        :disabled="(state.busy || customer.call_status !== 'Connected')"
                        placeholder="-- Please Select Disposition Type --"
                      >
                        <template #option="{ type_name }">
                          <span>{{ type_name }}</span>
                        </template>
                        <template #no-options="">
                          No Available Disposition Types
                        </template>
                      </v-select>
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                  sm="6"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="disposition"
                    vid="disposition"
                    :rules="rulesDisposition"
                  >
                    <b-form-group>
                      <label for="sub_disposition"><strong>Disposition</strong></label>
                      <v-select
                        id="disposition"
                        v-model="selected.disposition"
                        :options="list.dispositions"
                        :class="`${errors.length > 0 ? 'is-invalid' : ''}`"
                        label="disposition_name"
                        :disabled="(state.busy || customer.call_status !== 'Connected')"
                        placeholder="-- Please Select Disposition --"
                      >
                        <template #option="{ disposition_name }">
                          <span>{{ disposition_name }}</span>
                        </template>
                        <template #no-options="">
                          No Available Dispositions
                        </template>
                      </v-select>
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="promotion"
                    vid="promotion"
                    :rules="rulesPromotion"
                  >
                    <b-form-group>
                      <label for="promotion"><strong>Promotion</strong></label>
                      <v-select
                        id="promotion"
                        v-model="selected.promotion"
                        :options="list.promotions"
                        :class="`${errors.length > 0 ? 'is-invalid' : ''}`"
                        label="promotion_name"
                        :disabled="(state.busy || customer.call_status !== 'Connected' || parseInt(customer.disposition_type) !== 8)"
                        placeholder="-- Please Select Promotion --"
                      >
                        <template #option="{ promotion_name }">
                          <span>{{ promotion_name }}</span>
                        </template>
                        <template #no-options="">
                          No Available Promotions
                        </template>
                      </v-select>
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="marketing program"
                    vid="marketing_program"
                    rules=""
                  >
                    <b-form-group>
                      <label for="marketing_program"><strong>Marketing Program</strong></label>
                      <v-select
                        id="marketing_program"
                        v-model="selected.marketing_program"
                        :options="list.marketing_programs"
                        :class="`${errors.length > 0 ? 'is-invalid' : ''}`"
                        label="marketing_program_name"
                        :disabled="(state.busy || customer.call_status !== 'Connected')"
                        placeholder="-- Please Select Marketing Program --"
                      >
                        <template #option="{ marketing_program_name }">
                          <span>{{ marketing_program_name }}</span>
                        </template>
                        <template #no-options="">
                          No Available Marketing Programs
                        </template>
                      </v-select>
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="registrar"
                    vid="registrar"
                    :rules="rulesRegistrar"
                  >
                    <b-form-group>
                      <label for="registrar"><strong>Marketing Program Registrar</strong></label>
                      <v-select
                        id="registrar"
                        v-model="selected.registrar"
                        :options="list.lead_agents"
                        :class="`${errors.length > 0 ? 'is-invalid' : ''}`"
                        label="full_name"
                        :disabled="(state.busy || customer.call_status !== 'Connected')"
                        placeholder="-- Please Select Registrar --"
                      >
                        <template #option="{ full_name }">
                          <span>{{ full_name }}</span>
                        </template>
                        <template #no-options="">
                          No Available Registrars
                        </template>
                      </v-select>
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="nps rate"
                    vid="satisfaction_rate"
                    rules="required"
                  >
                    <b-form-group>
                      <label for="satisfaction_rate"><strong>Customer Satisfaction Rate</strong></label>
                      <b-form-select
                        id="satisfaction_rate"
                        v-model="customer.satisfaction_rate"
                        :options="options.satisfaction_rates"
                        :state="errors.length > 0 ? false : null"
                        :disabled="(state.busy || customer.call_status !== 'Connected')"
                      >
                        <template #first>
                          <b-form-select-option
                            :value="null"
                            disabled
                          >
                            -- Please Select NPS Rate --
                          </b-form-select-option>
                        </template>
                      </b-form-select>
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col cols="12">
                  <ValidationProvider
                    #default="{ errors }"
                    name="remarks"
                    vid="remarks"
                    rules="required|max:1000"
                  >
                    <b-form-group>
                      <label for="remarks"><strong>Remarks</strong></label>
                      <b-textarea
                        id="remarks"
                        v-model="customer.remarks"
                        type="text"
                        :state="errors.length > 0 ? false : null"
                        placeholder="enter remarks"
                        autocomplete="off"
                        rows="6"
                        max-rows="6"
                        :disabled="state.busy"
                      />
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  v-if="selected.current?.lead_agent?.user_scope === 'retail'"
                  cols="12"
                  class="mt-2 mb-2"
                >
                  <b-form-checkbox
                    id="for_visit"
                    v-model="customer.for_visit"
                    name="for_visit"
                    value="1"
                    unchecked-value="0"
                    :disabled="(state.busy || customer.province === null || customer.municipality === null || customer.barangay === null || customer.call_status === null || customer.call_status === 'Unsuccessful' || parseInt(customer.is_close)===1)"
                    switch
                  >
                    <strong>CUSTOMER NEEDS VISITATION?</strong>
                  </b-form-checkbox>
                </b-col>

                <b-col
                  v-if="parseInt(customer.for_visit) === 1 && selected.current?.lead_agent?.user_scope === 'retail'"
                  cols="12"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="lead agent"
                    vid="lead_agent"
                    :rules="rulesLeadAgent"
                  >
                    <b-form-group>
                      <label for="lead_agent"><strong>Lead Agent</strong></label>
                      <v-select
                        id="lead_agent"
                        v-model="selected.lead_agent"
                        :options="list.lead_agents"
                        :class="`${errors.length > 0 ? 'is-invalid' : ''}`"
                        label="full_name"
                        :disabled="state.busy"
                        placeholder="-- Please Select Lead Agent --"
                      >
                        <template #option="{ full_name }">
                          <span>{{ full_name }}</span>
                        </template>
                        <template #no-options="">
                          No Available Lead Agents
                        </template>
                      </v-select>
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  v-if="parseInt(customer.for_visit) === 1 && selected.current?.lead_agent?.user_scope === 'retail'"
                  cols="12"
                  sm="6"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="ticket number"
                    vid="ticket_number"
                    :rules="rulesTicketNumber"
                  >
                    <b-form-group>
                      <label for="ticket_number"><strong>Ticket Number</strong></label>
                      <b-input
                        id="ticket_number"
                        v-model="customer.ticket_number"
                        type="text"
                        :state="errors.length > 0 ? false : null"
                        placeholder="enter ticket number"
                        autocomplete="off"
                        :disabled="state.busy"
                      />
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  v-if="parseInt(customer.for_visit) === 1 && selected.current?.lead_agent?.user_scope === 'retail'"
                  cols="12"
                  sm="6"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="due date"
                    vid="due_date"
                    :rules="rulesTicketNumber"
                  >
                    <b-form-group>
                      <label for="due_date"><strong>Due Date</strong></label>
                      <b-input
                        id="due_date"
                        v-model="customer.due_date"
                        type="date"
                        :state="errors.length > 0 ? false : null"
                        :disabled="state.busy"
                      />
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  v-if="parseInt(customer.for_visit) === 1 && selected.current?.lead_agent?.user_scope === 'retail'"
                  cols="12"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="ticket category"
                    vid="ticket_category"
                    :rules="rulesTicketCategory"
                  >
                    <b-form-group>
                      <label for="ticket_category"><strong>Ticket Category</strong></label>
                      <v-select
                        id="ticket_category"
                        v-model="selected.ticket_category"
                        :options="list.ticket_categories"
                        :class="`${errors.length > 0 ? 'is-invalid' : ''}`"
                        label="category_name"
                        :disabled="state.busy"
                        placeholder="-- Please Select Ticket Category --"
                      >
                        <template #option="{ category_name }">
                          <span>{{ category_name }}</span>
                        </template>
                        <template #no-options="">
                          No Available Ticket Categories
                        </template>
                      </v-select>
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  v-if="parseInt(customer.for_visit) === 1 && selected.current?.lead_agent?.user_scope === 'retail'"
                  cols="12"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="visit remarks"
                    vid="visit_remarks"
                    :rules="rulesVisitationRemarks"
                  >
                    <b-form-group>
                      <label for="visit_remarks"><strong>Visit Remarks</strong></label>
                      <b-textarea
                        id="visit_remarks"
                        v-model="customer.visit_remarks"
                        type="text"
                        :state="errors.length > 0 ? false : null"
                        placeholder="enter visit remarks"
                        autocomplete="off"
                        rows="6"
                        max-rows="6"
                        :disabled="state.busy"
                      />
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col
                  cols="12"
                  class="mt-2 mb-2"
                >
                  <b-form-checkbox
                    id="is_close"
                    v-model="customer.is_close"
                    name="is_close"
                    value="1"
                    unchecked-value="0"
                    :disabled="(state.busy || customer.call_status === null || parseInt(customer.for_visit)===1 || customer.call_status === 'Unsuccessful')"
                    switch
                  >
                    <strong>MARK THIS CALL REQUEST AS CLOSE</strong>
                  </b-form-checkbox>
                </b-col>

              </b-row>
            </b-tab>
          </b-tabs>
        </form>
      </ValidationObserver>

      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="success"
          :disabled="(state.busy || state.cropHistoryEditing || state.cropDetailEditing || state.cropPriceEditing)"
          @click="ok()"
        >
          Update Record
        </b-button>
        <b-button
          variant="danger"
          :disabled="state.busy"
          @click="cancel()"
        >
          Cancel
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="modal-view-communication-log-detail"
      title="View Details"
      size="lg"
      scrollable
      no-close-on-backdrop
    >
      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label>Disposed At</label>
            <b-input
              :value="dateTimeFormatter(selected.log?.created_at)"
              :disabled="true"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label>Agent</label>
            <b-input
              :value="selected.log?.agent_name"
              :disabled="true"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label>Channel</label>
            <b-input
              :value="selected.log?.channel_name"
              :disabled="true"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label>Call Status</label>
            <b-input
              :value="selected.log?.call_status"
              :disabled="true"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
        >
          <b-form-group>
            <label>Unsuccessful Reason</label>
            <b-input
              :value="selected.log?.unsuccessful_reason_name"
              :disabled="true"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label>Disposition Type</label>
            <b-input
              :value="selected.log?.disposition_type_name"
              :disabled="true"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label>Disposition</label>
            <b-input
              :value="selected.log?.disposition_name"
              :disabled="true"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
        >
          <b-form-group>
            <label>Promotion</label>
            <b-input
              :value="selected.log?.promotion_name"
              :disabled="true"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group>
            <label>Remarks</label>
            <b-textarea
              :value="selected.log?.remarks"
              rows="10"
              max-rows="10"
              :disabled="true"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="danger"
          :disabled="state.busy"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="modal-view-log-detail"
      title="View Details"
      size="lg"
      scrollable
      no-close-on-backdrop
    >
      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label>Visited At</label>
            <b-input
              :value="dateTimeFormatter(selected.visitation_log?.created_at)"
              :disabled="true"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label>LGA / RFO</label>
            <b-input
              :value="selected.visitation_log?.user_name"
              :disabled="true"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
        >
          <b-form-group>
            <label>Purpose</label>
            <b-input
              :value="selected.visitation_log?.purpose_name"
              :disabled="true"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group>
            <label>Remarks</label>
            <b-textarea
              :value="selected.visitation_log?.remarks"
              rows="10"
              max-rows="10"
              :disabled="true"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
        >
          <p class="mb-1">
            Uploaded Photos
          </p>
          <b-table
            hover
            small
            striped
            responsive
            show-empty
            :fields="tableVisitationLogAttachments.fields"
            :items="selected.visitation_log?.visitation_log_attachments"
          >
            <template #cell(index)="row">
              {{ row.index + 1 }}
            </template>
            <template #cell(action)="row">
              <div class="text-nowrap">
                <b-button
                  size="sm"
                  class="mr-1"
                  @click="onPreviewVisitationLogAttachment(row.item)"
                >
                  Preview
                </b-button>
              </div>
            </template>
          </b-table>
        </b-col>

      </b-row>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="danger"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="modal-preview-log-attachment"
      :title="ModalAttachmentTitle"
      size="xl"
      scrollable
      no-close-on-backdrop
    >
      <b-img
        :src="visitationLogAttachment.preview_photo_path"
        fluid-grow
      />
      <template #modal-footer="{ cancel }">
        <b-button
          size="sm"
          variant="danger"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>
    </b-modal>

  </b-container>
</template>

<script>
import _ from 'lodash'
import { core } from '@/config/pluginInit'
import { AxiosError, AgentCallRequestService, SharedLogService, SharedListService } from '@/services'
import formatter from '@/mixins/formatter'
import misc from '@/mixins/misc'
import url from '@/mixins/url'

export default {
  name: 'AgentPendingCalls',

  middleware: ['auth', 'agent'],

  mixins: [formatter, misc, url],

  metaInfo () {
    return {
      title: 'Pending  Calls'
    }
  },

  data () {
    return {
      state: {
        busy: false,
        editing: false,
        cropDetailEditing: false,
        cropHistoryEditing: false,
        cropPriceEditing: false
      },
      filter: {
        cluster: 'all',
        area: 'all',
        filter_cluster: 'all',
        filter_area: 'all'
      },
      list: {
        filter_clusters: [],
        filter_areas: [],
        clusters: [],
        areas: [],
        provinces: [],
        municipalities: [],
        barangays: [],
        promotions: [],
        mode_of_communications: [],
        mode_of_payments: [],
        dealers: [],
        dialects: [],
        raisers: [],
        packages: [],
        custom_fields: [],
        cropping_seasons: [
          { text: 'Wet', value: 'Wet' },
          { text: 'Dry', value: 'Dry' }
        ],
        cropping_years: [
          { text: '2022', value: 2022 },
          { text: '2023', value: 2023 },
          { text: '2024', value: 2024 },
          { text: '2025', value: 2025 },
          { text: '2026', value: 2026 },
          { text: '2027', value: 2027 },
          { text: '2028', value: 2028 },
          { text: '2029', value: 2029 },
          { text: '2030', value: 2030 },
          { text: '2031', value: 2031 },
          { text: '2032', value: 2032 }
        ],
        selling_conditions: [
          { text: 'Dry', value: 'Dry' },
          { text: 'Fresh', value: 'Fresh' }
        ],
        crops: [],
        classifications: [],
        field_sizes: [],
        varieties: [],
        herbicides: [],
        fungicides: [],
        insecticides: [],
        fertilizers: [],
        unsuccessful_reasons: [],
        disposition_types: [],
        dispositions: [],
        lead_agents: [],
        ticket_categories: [],
        filter_marketing_programs: []
      },
      options: {
        genders: [
          { text: 'Male', value: 'Male' },
          { text: 'Female', value: 'Female' }
        ],
        customer_types: [
          { text: 'Farmer', value: 'Farmer' },
          { text: 'Non-Farmer', value: 'Non-Farmer' },
          { text: 'Dealer', value: 'Dealer' }
        ],
        schedules: [
          { text: 'Morning', value: 'Morning' },
          { text: 'Afternoon', value: 'Afternoon' },
          { text: 'Evening', value: 'Evening' }
        ],
        call_statuses: [
          { text: 'Connected', value: 'Connected' },
          { text: 'Unsuccessful', value: 'Unsuccessful' }
        ],
        satisfaction_rates: [
          { text: 0, value: 0 },
          { text: 1, value: 1 },
          { text: 2, value: 2 },
          { text: 3, value: 3 },
          { text: 4, value: 4 },
          { text: 5, value: 5 },
          { text: 6, value: 6 },
          { text: 7, value: 7 },
          { text: 8, value: 8 },
          { text: 9, value: 9 },
          { text: 10, value: 10 }
        ],
        filter_call_statuses: [
          { text: 'None', value: 'None' },
          { text: 'Connected', value: 'Connected' },
          { text: 'Unsuccessful', value: 'Unsuccessful' }
        ]
      },
      visitationLogAttachment: {
        preview_photo_name: null,
        preview_photo_path: null
      },
      selected: {
        current: null,
        log: null,
        call_request: null,
        visitation_log: null,
        filter_cluster: null,
        filter_area: null,
        cluster: null,
        area: null,
        province: null,
        municipality: null,
        barangay: null,
        call_status: null,
        unsuccessful_reason: null,
        disposition_type: null,
        disposition: null,
        promotion: null,
        marketing_program: null,
        registrar: null,
        lead_agent: null,
        ticket_category: null
      },
      selected2: {
        current: null,
        cropping_season: null,
        cropping_year: null,
        crop: null,
        classification: null,
        varieties: null,
        herbicide_ones: null,
        herbicide_twos: null,
        fungicides: null,
        insecticides: null,
        fertilizers: null
      },
      customer: {
        call_request_id: 0,
        id: 0,
        first_name: '',
        last_name: '',
        mobile_number: '',
        email: '',
        customer_type: null,
        gender: null,
        birth_date: null,
        primary_dialect: null,
        secondary_dialect: null,
        experience: 0,
        number_of_household: 0,
        occupation: '',
        other_occupation: '',
        dealers: [],
        other_dealer: '',
        cluster: null,
        area: null,
        street_name: '',
        province: null,
        municipality: null,
        barangay: null,
        farm_address: '',
        mop: null,
        moc: null,
        preferred_time: null,
        raisers: [],
        packages: [],
        custom_fields: [],
        dirty_custom_fields: [],
        crop_details: [],
        dirty_crop_details: [],
        crop_histories: [],
        dirty_crop_histories: [],
        crop_prices: [],
        dirty_crop_prices: [],
        marketing_programs: [],
        channel: 1,
        call_status: null,
        unsuccessful_reason: null,
        disposition_type: null,
        disposition: null,
        promotion: null,
        marketing_program: null,
        registrar: null,
        satisfaction_rate: 0,
        remarks: '',
        for_visit: 0,
        lead_agent: null,
        ticket_number: '',
        due_date: null,
        ticket_category: null,
        visit_remarks: '',
        is_close: 0
      },
      customField: {
        field: null,
        field_value: ''
      },
      cropDetail: {
        id: 0,
        active: 0,
        crop: null,
        crop_name: null,
        field_size: null,
        field_size_name: null,
        number_of_hectares: 0,
        created_at: null,
        updated_at: null
      },
      cropHistory: {
        id: 0,
        active: 0,
        cropping_season: null,
        cropping_year: null,
        crop: null,
        crop_name: null,
        classification: null,
        classification_name: null,
        varieties: [],
        variety_names: null,
        herbicide_ones: [],
        herbicide_one_names: null,
        herbicide_twos: [],
        herbicide_two_names: null,
        fungicides: [],
        fungicide_names: null,
        insecticides: [],
        insecticide_names: null,
        fertilizers: [],
        fertilizer_names: null,
        bag_per_ha: 0
      },
      cropPrice: {
        id: 0,
        active: 0,
        cropping_season: null,
        cropping_year: null,
        crop: null,
        total_harvested: 0,
        selling_condition: null,
        selling_price: 0
      },
      tableSettings: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: '',
        filterOn: [],
        fields: [
          'index',
          'actions',
          { key: 'created_at', formatter: this.dateTimeFormatter, sortable: true },
          { key: 'status', sortable: true },
          { key: 'customer.first_name', label: 'first name' },
          { key: 'customer.last_name', label: 'last name' },
          { key: 'customer.mobile_number', label: 'mobile number' },
          { key: 'customer.cluster.cluster_name', label: 'cluster' },
          { key: 'customer.area.area_name', label: 'area' },
          { key: 'lead_name', label: 'requested by' },
          { key: 'call_attempt', label: 'unsuccessful call attempt' },
          { key: 'last_call', formatter: this.dateTimeFormatter },
          { key: 'last_status' },
          { key: 'last_agent' },
          { key: 'updated_at', formatter: this.dateTimeFormatter }
        ]
      },
      tableCropDetails: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: true,
        sortDirection: 'desc',
        filter: '',
        filterOn: [],
        fields: [
          'index',
          'actions',
          { key: 'created_at', formatter: this.dateTimeFormatter, sortable: true },
          { key: 'crop_name', label: 'Crop' },
          { key: 'field_size_name', label: 'Field Size' },
          { key: 'number_of_hectares', label: 'Number of Hectares' },
          { key: 'updated_at', formatter: this.dateTimeFormatter }
        ]
      },
      tableCropHistories: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: true,
        sortDirection: 'desc',
        filter: '',
        filterOn: [],
        fields: [
          'index',
          'actions',
          { key: 'created_at', formatter: this.dateTimeFormatter, sortable: true },
          { key: 'cropping_season' },
          { key: 'cropping_year' },
          { key: 'crop_name' },
          { key: 'classification_name' },
          { key: 'variety_names' },
          { key: 'herbicide_one_names' },
          { key: 'herbicide_two_names' },
          { key: 'fungicide_names' },
          { key: 'insecticide_names' },
          { key: 'fertilizer_names' },
          { key: 'bag_per_ha', label: 'Bag Per Hectare' },
          { key: 'updated_at', formatter: this.dateTimeFormatter }
        ]
      },
      tableCropPrices: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: true,
        sortDirection: 'desc',
        filter: '',
        filterOn: [],
        fields: [
          'index',
          'actions',
          { key: 'created_at', formatter: this.dateTimeFormatter, sortable: true },
          { key: 'cropping_season' },
          { key: 'cropping_year' },
          { key: 'crop_name' },
          { key: 'total_harvested' },
          { key: 'selling_condition' },
          { key: 'selling_price' },
          { key: 'updated_at', formatter: this.dateTimeFormatter }
        ]
      },
      tableVisitationLogSettings: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: '',
        filterOn: [],
        fields: [
          'index',
          'actions',
          { key: 'visitation_date', formatter: this.dateFormatter },
          { key: 'user_name', label: 'lga / rfo' },
          { key: 'purpose_name', label: 'purpose' },
          { key: 'created_at', formatter: this.dateTimeFormatter },
          { key: 'updated_at', formatter: this.dateTimeFormatter }
        ]
      },
      tableMarketingPrograms: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: true,
        sortDirection: 'desc',
        filter: '',
        filterOn: [],
        fields: [
          'index',
          { key: 'pivot.created_at', label: 'Enrollment Date', formatter: this.dateTimeFormatter, sortable: true },
          { key: 'marketing_program_name', label: 'marketing program' }
        ]
      },
      tableCommunicationLogSettings: {
        perPage: 5,
        pageOptions: [5, 10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: '',
        filterOn: [],
        fields: [
          'index',
          'actions',
          { key: 'created_at', formatter: this.dateTimeFormatter, sortable: true },
          { key: 'agent_name', label: 'agent' },
          { key: 'channel_name', label: 'channel' },
          { key: 'call_status' },
          { key: 'unsuccessful_reason_name', label: 'unsuccessful reason' },
          { key: 'disposition_type_name', label: 'disposition type' },
          { key: 'disposition_name', label: 'disposition' },
          { key: 'promotion_name', label: 'promotion' }
        ]
      },
      tableCallRequestLogSettings: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: '',
        filterOn: [],
        fields: [
          'index',
          'actions',
          { key: 'created_at', formatter: this.dateTimeFormatter, sortable: true },
          { key: 'agent_name', label: 'agent' },
          { key: 'channel_name' },
          { key: 'call_status' },
          { key: 'unsuccessful_reason_name', label: 'unsuccessful reason' },
          { key: 'disposition_type_name', label: 'disposition type' },
          { key: 'disposition_name', label: 'disposition' }
        ]
      },
      tableVisitationLogAttachments: {
        fields: [
          { key: 'index', class: 'text-center' },
          { key: 'action', class: 'text-center' },
          { key: 'file_original_name', label: 'file name' }
        ]
      },
      allComments: ''
    }
  },

  computed: {
    rulesUnsuccessfulReason () {
      if (this.customer.call_status === 'Unsuccessful') {
        return 'required'
      }

      return ''
    },

    rulesDispositionType () {
      if (this.customer.call_status === 'Connected') {
        return 'required'
      }

      return ''
    },

    rulesDisposition () {
      if (this.customer.call_status === 'Connected') {
        return 'required'
      }

      return ''
    },

    rulesRegistrar () {
      if (this.customer.marketing_program !== null) {
        return 'required'
      }

      return ''
    },

    rulesPromotion () {
      if (this.customer.call_status === 'Connected' && parseInt(this.customer.disposition_type) === 8) {
        return 'required'
      }

      return ''
    },

    rulesLeadAgent () {
      if (parseInt(this.customer.for_visit) === 1) {
        return 'required'
      }

      return ''
    },

    rulesTicketNumber () {
      if (parseInt(this.customer.for_visit) === 1) {
        return 'required|max:10'
      }

      return 'max:10'
    },

    rulesTicketCategory () {
      if (parseInt(this.customer.for_visit) === 1) {
        return 'required'
      }

      return ''
    },

    rulesVisitationRemarks () {
      if (parseInt(this.customer.for_visit) === 1) {
        return 'required|max:1000'
      }

      return ''
    },

    filteredCustomerMarketingPrograms () {
      return this.customer.marketing_programs.filter(item => item.active === 1)
    },

    customFieldInserButttonDisabledState () {
      return !(this.customField.field && this.customField.field_value.length > 0)
    },

    filteredCustomerCropDetails () {
      return this.customer.crop_details.filter(item => item.active === 1)
    },

    cropDetailButtonLabel () {
      if (this.state.cropDetailEditing) {
        return 'Update Detail'
      }

      return 'Insert Detail'
    },

    cropDetailButtonVariant () {
      if (this.state.cropDetailEditing) {
        return 'secondary'
      }

      return 'success'
    },

    cropDetailButtonDisabledState () {
      return !(this.cropDetail.crop && this.cropDetail.field_size && parseFloat(this.cropDetail.number_of_hectares) > 0)
    },

    filteredCustomerCropHistories () {
      return this.customer.crop_histories.filter(item => item.active === 1)
    },

    cropHistoryButtonLabel () {
      if (this.state.cropHistoryEditing) {
        return 'Update Detail'
      }

      return 'Insert Detail'
    },

    cropHistoryButtonVariant () {
      if (this.state.cropHistoryEditing) {
        return 'secondary'
      }

      return 'success'
    },

    cropHistoryButtonDisabledState () {
      return !(this.cropHistory.cropping_season && this.cropHistory.cropping_year && this.cropHistory.crop)
    },

    filteredCustomerCropPrices () {
      return this.customer.crop_prices.filter(item => item.active === 1)
    },

    cropPriceButtonLabel () {
      if (this.state.cropPriceEditing) {
        return 'Update Detail'
      }

      return 'Insert Detail'
    },

    cropPriceButtonVariant () {
      if (this.state.cropPriceEditing) {
        return 'secondary'
      }

      return 'success'
    },

    cropPriceButtonDisabledState () {
      return !(this.cropPrice.cropping_season && this.cropPrice.cropping_year && this.cropPrice.crop && this.cropPrice.selling_condition && parseFloat(this.cropPrice.selling_price) > 0)
    },

    ModalAttachmentTitle () {
      return this.visitationLogAttachment.preview_photo_name
    }
  },

  watch: {
    'selected.province' (value) {
      this.customer.province = value?.id || null
      this.getMunicipalities(this.customer.province)
    },

    'selected.municipality' (value) {
      this.customer.municipality = value?.id || null
      this.getBarangays(this.customer.municipality)
    },

    'selected.barangay' (value) {
      this.customer.barangay = value?.id || null
    },

    'selected.cluster' (value) {
      this.customer.cluster = value?.id || null
      this.getAreas(this.customer.cluster)
    },

    'selected.area' (value) {
      this.customer.area = value?.id || null
    },

    'selected.filter_cluster' (value) {
      this.filter.filter_cluster = value?.id || 'all'
      this.getFilterAreas(value?.id || null)
      this.onFilterChanged()
    },

    'selected.lead_agent' (value) {
      this.customer.lead_agent = value?.id || null
    },

    'selected.ticket_category' (value) {
      this.customer.ticket_category = value?.id || null
    },

    'selected.filter_area' (value) {
      this.filter.filter_area = value?.id || 'all'
      this.onFilterChanged()
    },

    'selected.call_status' (value) {
      this.customer.call_status = value?.value || null
      if (!this.customer.call_status) {
        this.selected.unsuccessful_reason = null
        this.selected.disposition_type = null
        this.selected.disposition = null
        this.selected.promotion = null
        this.selected.marketing_program = null
        this.selected.registrar = null
        this.customer.satisfaction_rate = 0
        this.customer.for_visit = 0
        this.customer.is_close = 0
      }

      if (this.customer.call_status === 'Connected') {
        this.selected.unsuccessful_reason = null
      }

      if (this.customer.call_status === 'Unsuccessful') {
        this.selected.disposition_type = null
        this.selected.disposition = null
        this.selected.promotion = null
        this.selected.marketing_program = null
        this.selected.registrar = null
        this.customer.satisfaction_rate = 0
        this.customer.for_visit = 0
        this.customer.is_close = 0
      }
    },

    'selected.unsuccessful_reason' (value) {
      this.customer.unsuccessful_reason = value?.id || null
    },

    'selected.disposition_type' (value) {
      this.customer.disposition_type = value?.id || null
      if (parseInt(this.customer.disposition_type) !== 8) {
        this.selected.promotion = null
      }
      this.getDispositions(this.customer.disposition_type)
    },

    'selected.disposition' (value) {
      this.customer.disposition = value?.id || null
    },

    'selected.promotion' (value) {
      this.customer.promotion = value?.id || null
    },

    'selected.marketing_program' (value) {
      this.customer.marketing_program = value?.id || null

      if (this.customer.marketing_program === null) {
        this.selected.registrar = null
      }
    },

    'selected.registrar' (value) {
      this.customer.registrar = value?.id || null
    },

    'selected2.cropping_season' (value) {
      this.cropHistory.cropping_season = value?.value || null
    },

    'selected2.cropping_year' (value) {
      this.cropHistory.cropping_year = value?.value || null
    },

    'selected2.crop' (value) {
      this.cropHistory.crop = value?.value || null
      this.getClassifications(this.cropHistory.crop)
      this.getVarieties(this.cropHistory.crop)
      this.getHerbicides(this.cropHistory.crop)
      this.getFungicides(this.cropHistory.crop)
      this.getInsecticides(this.cropHistory.crop)
      this.getFertilizers(this.cropHistory.crop)
    },

    'selected2.classification' (value) {
      this.cropHistory.classification = value?.id || null
    },

    'selected2.varieties' (value) {
      this.cropHistory.varieties = value
    },

    'selected2.herbicide_ones' (value) {
      this.cropHistory.herbicide_ones = value
    },

    'selected2.herbicide_twos' (value) {
      this.cropHistory.herbicide_twos = value
    },

    'selected2.fungicides' (value) {
      this.cropHistory.fungicides = value
    },

    'selected2.insecticides' (value) {
      this.cropHistory.insecticides = value
    },

    'selected2.fertilizers' (value) {
      this.cropHistory.fertilizers = value
    },

    'customer.for_visit' (value) {
      if (parseInt(value) === 0) {
        this.selected.lead_agent = null
        this.customer.ticket_number = ''
        this.customer.due_date = null
        this.selected.ticket_category = null
        this.customer.visit_remarks = ''
        this.customer.is_close = 0
      }
    }
  },

  mounted () {
    core.index()
    this.getClusters()
    this.getFilterClusters()
    this.getProvinces()
    this.getModeOfPayments()
    this.getModeOfCommunications()
    this.getDialects()
    this.getDealers()
    this.getCrops()
    this.getFieldSizes()
    this.getUnsuccessfulReasons()
    this.getDispositionTypes()
    this.getPromotions()
    this.getMarketingPrograms()
    this.getCustomFields()
    this.getRaisers()
    this.getLeadAgents()
    this.getTicketCategories()
  },

  methods: {
    async myProvider (ctx) {
      try {
        const { data } = await AgentCallRequestService.get(
          this.objectToUrl({
            page: ctx.currentPage,
            per_page: ctx.perPage,
            sort: ctx.sortBy,
            sort_desc: ctx.sortDesc,
            filter_text: ctx.filter,
            filter_cluster: this.filter.filter_cluster,
            filter_area: this.filter.filter_area
          })
        )
        this.tableSettings.totalRows = data.total_rows
        return data.items
      } catch {
        return []
      }
    },

    async myVisitationLogProvider (ctx) {
      try {
        const { data } = await SharedLogService.getVisitationLogs(
          this.objectToUrl({
            page: ctx.currentPage,
            per_page: ctx.perPage,
            sort: ctx.sortBy,
            sort_desc: ctx.sortDesc,
            filter_text: ctx.filter,
            customer_id: this.customer.id
          })
        )
        this.tableVisitationLogSettings.totalRows = data.total_rows
        return data.items
      } catch {
        return []
      }
    },

    async myLogProvider (ctx) {
      try {
        const { data } = await SharedLogService.getCustomerLogs(
          this.objectToUrl({
            page: ctx.currentPage,
            per_page: ctx.perPage,
            sort: ctx.sortBy,
            sort_desc: ctx.sortDesc,
            filter_text: ctx.filter,
            customer_id: this.customer.id
          })
        )
        this.tableCommunicationLogSettings.totalRows = data.total_rows
        this.allComments = ''
        data.items.forEach(log => {
          const remarks = `${this.dateTimeFormatterLong(log.created_at)} [${log.agent_name} thru ${log.channel_name}]: ${log.remarks}\r\n\r\n`
          this.allComments += remarks
        })
        return data.items
      } catch (e) {
        return []
      }
    },

    async myCallRequestLogProvider (ctx) {
      try {
        const { data } = await SharedLogService.getCustomerLogs(
          this.objectToUrl({
            page: ctx.currentPage,
            per_page: ctx.perPage,
            sort: ctx.sortBy,
            sort_desc: ctx.sortDesc,
            filter_text: ctx.filter,
            customer_id: this.selected.call_request.customer?.id,
            request_id: this.selected.call_request.id
          })
        )
        this.tableCallRequestLogSettings.totalRows = data.total_rows
        return data.items
      } catch {
        return []
      }
    },

    async getProvinces () {
      await SharedListService.getProvinces().then(({ data }) => {
        this.list.provinces = data
      })
    },

    async getMunicipalities (province) {
      this.selected.municipality = null
      if (province) {
        await SharedListService.getMunicipalities(`province_id=${province}`).then(({ data }) => {
          this.list.municipalities = data

          if (this.state.editing) {
            if (this.selected.current.customer.municipality) {
              this.selected.municipality = {
                id: this.selected.current.customer.municipality.id,
                municipality_name: this.selected.current.customer.municipality.municipality_name
              }
              // this.selected.current.customer.municipality = null
            }
          }
        })
      } else {
        this.list.municipalities = []
      }
    },

    async getBarangays (municipality) {
      this.selected.barangay = null
      if (municipality) {
        await SharedListService.getBarangays(`municipality_id=${municipality}`).then(({ data }) => {
          this.list.barangays = data

          if (this.state.editing) {
            if (this.selected.current.customer.barangay) {
              this.selected.barangay = {
                id: this.selected.current.customer.barangay.id,
                barangay_name: this.selected.current.customer.barangay.barangay_name
              }
              // this.selected.current.customer.barangay = null
            }
          }
        })
      } else {
        this.list.barangays = []
      }
    },

    async getClusters () {
      await SharedListService.getClusters().then(({ data }) => {
        this.list.clusters = data
      })
    },

    async getAreas (cluster) {
      this.selected.area = null
      if (cluster) {
        await SharedListService.getAreas(`cluster_id=${cluster}`).then(({ data }) => {
          this.list.areas = data

          if (this.state.editing) {
            if (this.selected.current.customer.area) {
              this.selected.area = {
                id: this.selected.current.customer.area.id,
                area_name: this.selected.current.customer.area.area_name
              }
              this.selected.current.customer.area = null
            }
          }
        })
      } else {
        this.list.areas = []
      }
    },

    async getFilterClusters () {
      await SharedListService.getClusters().then(({ data }) => {
        this.list.filter_clusters = data
      })
    },

    async getFilterAreas (cluster) {
      this.selected.filter_area = null
      if (cluster) {
        await SharedListService.getAreas(`cluster_id=${cluster}`).then(({ data }) => {
          this.list.filter_areas = data
        })
      } else {
        this.list.filter_areas = []
      }
    },

    async getModeOfCommunications () {
      await SharedListService.getModeOfCommunications().then(({ data }) => {
        this.list.mode_of_communications = data.map(item => {
          return {
            text: item.moc_name,
            value: item.id
          }
        })
      })
    },

    async getModeOfPayments () {
      await SharedListService.getModeOfPayments().then(({ data }) => {
        this.list.mode_of_payments = data.map(item => {
          return {
            text: item.mop_name,
            value: item.id
          }
        })
      })
    },

    async getDealers () {
      await SharedListService.getDealers().then(({ data }) => {
        this.list.dealers = data
      })
    },

    async getRaisers () {
      await SharedListService.getRaisers().then(({ data }) => {
        this.list.raisers = data
      })
    },

    async getDialects () {
      await SharedListService.getDialects().then(({ data }) => {
        this.list.dialects = data.map(item => {
          return {
            text: item.dialect_name,
            value: item.id
          }
        })
      })
    },

    async getCustomFields () {
      await SharedListService.getCustomFields().then(({ data }) => {
        this.list.custom_fields = data.map(item => {
          return {
            text: item.field_label,
            value: item.id
          }
        })
      })
    },

    async getCrops () {
      await SharedListService.getCrops().then(({ data }) => {
        this.list.crops = data.map(item => {
          return {
            text: item.crop_name,
            value: item.id
          }
        })
      })
    },

    async getClassifications (crop) {
      this.selected2.classification = null
      if (crop) {
        await SharedListService.getClassifications(`crop=${crop}`).then(({ data }) => {
          this.list.classifications = data
          if (this.state.cropHistoryEditing) {
            if (this.selected2.current.classification) {
              this.selected2.classification = _.find(this.list.classifications, { id: this.selected2.current.classification })
              this.selected2.current.classification = null
            }
          }
        })
      } else {
        this.list.classifications = []
      }
    },

    async getVarieties (crop) {
      this.selected2.varieties = null
      if (crop) {
        await SharedListService.getVarieties(`crop=${crop}`).then(({ data }) => {
          this.list.varieties = data
          if (this.state.cropHistoryEditing) {
            if (this.selected2.current.varieties) {
              this.selected2.varieties = this.selected2.current.varieties
              this.selected2.current.varieties = null
            }
          }
        })
      } else {
        this.list.varieties = []
      }
    },

    async getHerbicides (crop) {
      this.selected2.herbicide_ones = null
      this.selected2.herbicide_twos = null
      if (crop) {
        await SharedListService.getHerbicides(`crop=${crop}`).then(({ data }) => {
          this.list.herbicides = data
          if (this.state.cropHistoryEditing) {
            if (this.selected2.current.herbicide_ones) {
              this.selected2.herbicide_ones = this.selected2.current.herbicide_ones
              this.selected2.current.herbicide_ones = null
            }
            if (this.selected2.current.herbicide_twos) {
              this.selected2.herbicide_twos = this.selected2.current.herbicide_twos
              this.selected2.current.herbicide_twos = null
            }
          }
        })
      } else {
        this.list.herbicides = []
      }
    },

    async getFungicides (crop) {
      this.selected2.fungicides = null
      if (crop) {
        await SharedListService.getFungicides(`crop=${crop}`).then(({ data }) => {
          this.list.fungicides = data
          if (this.state.cropHistoryEditing) {
            if (this.selected2.current.fungicides) {
              this.selected2.fungicides = this.selected2.current.fungicides
              this.selected2.current.fungicides = null
            }
          }
        })
      } else {
        this.list.fungicides = []
      }
    },

    async getInsecticides (crop) {
      this.selected2.insecticides = null
      if (crop) {
        await SharedListService.getInsecticides(`crop=${crop}`).then(({ data }) => {
          this.list.insecticides = data
          if (this.state.cropHistoryEditing) {
            if (this.selected2.current.insecticides) {
              this.selected2.insecticides = this.selected2.current.insecticides
              this.selected2.current.insecticides = null
            }
          }
        })
      } else {
        this.list.insecticides = []
      }
    },

    async getFertilizers (crop) {
      this.selected2.fertilizers = null
      if (crop) {
        await SharedListService.getFertilizers(`crop=${crop}`).then(({ data }) => {
          this.list.fertilizers = data
          if (this.state.cropHistoryEditing) {
            if (this.selected2.current.fertilizers) {
              this.selected2.fertilizers = this.selected2.current.fertilizers
              this.selected2.current.fertilizers = null
            }
          }
        })
      } else {
        this.list.fertilizers = []
      }
    },

    async getFieldSizes () {
      await SharedListService.getFieldSizes().then(({ data }) => {
        this.list.field_sizes = data.map(item => {
          return {
            text: item.field_size_name,
            value: item.id
          }
        })
      })
    },

    async getUnsuccessfulReasons () {
      await SharedListService.getUnsuccessfulReasons().then(({ data }) => {
        this.list.unsuccessful_reasons = data
      })
    },

    async getDispositionTypes () {
      await SharedListService.getDispositionTypes(`channel_id=${this.customer.channel}`).then(({ data }) => {
        const filter = [3, 6, 18]
        this.list.disposition_types = data.filter(item => {
          return !filter.includes(item.id)
        })
      })
    },

    async getDispositions (dispositionType) {
      this.selected.disposition = null
      if (dispositionType) {
        await SharedListService.getDispositions(`disposition_type_id=${dispositionType}`).then(({ data }) => {
          this.list.dispositions = data
        })
      } else {
        this.list.dispositions = []
      }
    },

    async getPromotions () {
      await SharedListService.getPromotions().then(({ data }) => {
        this.list.promotions = data
      })
    },

    async getMarketingPrograms () {
      await SharedListService.getMarketingPrograms().then(({ data }) => {
        this.list.marketing_programs = data
      })
    },

    onShowDetailForm (current) {
      this.selected.call_request = { ...current }
      this.$bvModal.show('modal-view-request-detail')
    },

    async getLeadAgents () {
      await SharedListService.getLeadAgents().then(({ data }) => {
        this.list.lead_agents = data
      })
    },

    async getTicketCategories () {
      await SharedListService.getTicketCategories().then(({ data }) => {
        this.list.ticket_categories = data
      })
    },

    onShowEditForm (current) {
      this.state.editing = true
      this.selected.current = { ...current }
      this.selected.cluster = null
      this.selected.province = null
      this.selected.call_status = null
      this.selected.unsuccessful_reason = null
      this.selected.disposition_type = null
      this.selected.disposition = null
      this.selected.promotion = null
      this.selected.marketing_program = null
      this.selected.registrar = null
      this.customer.call_request_id = current.id
      this.customer.for_visit = 0
      this.selected.lead_agent = null
      this.customer.ticket_number = ''
      this.customer.due_date = null
      this.selected.ticket_category = null
      this.customer.visit_remarks = ''
      this.customer.is_close = 0
      this.customer.id = current.customer?.id
      this.customer.active = current.customer?.active
      this.customer.first_name = current.customer?.first_name
      this.customer.last_name = current.customer?.last_name
      this.customer.customer_type = current.customer?.customer_type
      this.customer.mobile_number = current.customer?.mobile_number
      this.customer.email = current.customer?.email
      this.customer.birth_date = current.customer?.birth_date
      this.customer.gender = current.customer?.gender
      this.customer.experience = current.customer?.experience
      this.customer.primary_dialect = current.customer?.primary_dialect?.id || null
      this.customer.secondary_dialect = current.customer?.secondary_dialect?.id || null
      this.customer.number_of_household = current.customer?.number_of_household
      this.customer.occupation = current.customer?.occupation
      this.customer.other_occupation = current.customer?.other_occupation
      this.customer.dealers = current.customer?.dealers
      this.customer.other_dealer = current.customer?.other_dealer
      this.customer.cluster = current.customer?.cluster_id
      this.customer.area = current.customer?.area_id
      this.customer.street_name = current.customer?.street_name
      this.customer.province = current.customer?.province_id
      this.customer.municipality = current.customer?.municipality_id
      this.customer.barangay = current.customer?.barangay_id
      this.customer.farm_address = current.customer?.farm_address
      this.customer.mop = current.customer?.mop?.id || null
      this.customer.moc = current.customer?.moc?.id || null
      this.customer.preferred_time = current.customer?.preferred_time
      this.customer.raisers = current.customer?.raisers
      this.customer.packages = current.customer?.packages
      this.customer.custom_fields = current.customer?.details
      this.customer.satisfaction_rate = 0
      this.customer.remarks = ''

      this.customer.crop_details = current.customer?.crop_details.map(item => {
        return {
          id: item.id,
          active: item.active,
          crop: item.crop_id,
          crop_name: item.crop ? item.crop.crop_name : null,
          field_size: item.crop_field_size_id,
          field_size_name: item.field_size ? item.field_size.field_size_name : null,
          number_of_hectares: item.number_of_hectares,
          created_at: item.created_at,
          updated_at: item.updated_at
        }
      })

      this.customer.crop_histories = current.customer?.crop_histories.map(item => {
        return {
          id: item.id,
          active: item.active,
          cropping_season: item.cropping_season,
          cropping_year: item.cropping_year,
          crop: item.crop_id,
          crop_name: item.crop ? item.crop.crop_name : null,
          classification: item.crop_classification_id,
          classification_name: item.crop_classification ? item.crop_classification.classification_name : null,
          varieties: item.crop_varieties,
          variety_names: item.crop_varieties ? item.crop_varieties.map(s => s.variety_name).toString() : null,
          herbicide_ones: item.herbicide_ones,
          herbicide_one_names: item.herbicide_ones ? item.herbicide_ones.map(s => s.herbicide_name).toString() : null,
          herbicide_twos: item.herbicide_twos,
          herbicide_two_names: item.herbicide_twos ? item.herbicide_twos.map(s => s.herbicide_name).toString() : null,
          fungicides: item.fungicides,
          fungicide_names: item.fungicides ? item.fungicides.map(s => s.fungicide_name).toString() : null,
          insecticides: item.insecticides,
          insecticide_names: item.insecticides ? item.insecticides.map(s => s.insecticide_name).toString() : null,
          fertilizers: item.fertilizers,
          fertilizer_names: item.fertilizers ? item.fertilizers.map(s => s.fertilizer_name).toString() : null,
          bag_per_ha: item.bag_per_ha,
          created_at: item.created_at,
          updated_at: item.updated_at
        }
      })

      this.customer.crop_prices = current.customer?.crop_prices.map(item => {
        return {
          id: item.id,
          active: item.active,
          cropping_season: item.cropping_season,
          cropping_year: item.cropping_year,
          crop: item.crop_id,
          crop_name: item.crop ? item.crop.crop_name : null,
          total_harvested: item.total_harvested,
          selling_condition: item.selling_condition,
          selling_price: item.selling_price,
          created_at: item.created_at,
          updated_at: item.updated_at
        }
      })

      this.customer.marketing_programs = current.customer?.marketing_programs

      if (current.customer?.cluster) {
        this.selected.cluster = {
          id: current.customer?.cluster.id,
          cluster_name: current.customer?.cluster.cluster_name
        }
      }

      if (current.customer?.province) {
        this.selected.province = {
          id: current.customer?.province.id,
          province_name: current.customer?.province.province_name
        }
      }

      // reset state
      this.state.cropDetailEditing = false
      this.state.cropHistoryEditing = false
      this.state.cropPriceEditing = false

      // reset details
      this.onCustomFieldReset()
      this.onCropDetailReset()
      this.onCropHistoryReset()
      this.onCropPriceReset()

      // reset dirty
      this.customer.dirty_custom_fields = []
      this.customer.dirty_crop_details = []
      this.customer.dirty_crop_histories = []
      this.customer.dirty_crop_prices = []

      // show modal form
      this.$bvModal.show('modal-edit-form')
    },

    onShowLogForm (current) {
      this.customer.id = current.id
      this.customer.first_name = current.first_name
      this.customer.last_name = current.last_name
      this.customer.customer_type = current.customer_type
      this.customer.mobile_number = current.mobile_number
      this.$bvModal.show('modal-log-form')
    },

    onShowLogDetail (current) {
      this.selected.visitation_log = { ...current }
      this.$bvModal.show('modal-view-log-detail')
    },

    onPreviewVisitationLogAttachment (attachment) {
      this.visitationLogAttachment.preview_photo_name = attachment.file_original_name
      this.visitationLogAttachment.preview_photo_path = this.getImageUrl(`${this.selected.visitation_log?.attachment_path}/${attachment.file_hash_name}`)
      this.$bvModal.show('modal-preview-log-attachment')
    },

    async onConfirmAction (bvModalEvt) {
      bvModalEvt.preventDefault()
      await this.$refs.form.validate().then(async success => {
        if (success) {
          const title = 'Do you really want to save changes?'
          this.$swal.fire({
            icon: 'question',
            title: 'Question',
            text: title,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Yes',
            cancelButtonColor: '#FF2929',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            preConfirm: () => {
              if (this.state.editing) {
                return this.onFormPutSubmit()
              }
            },
            allowOutsideClick: () => !this.$swal.isLoading()
          })
        } else {
          this.$bvModal.msgBoxOk('Oops! There were problem with your inputs.', {
            title: 'Validation Error',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            centered: true
          })
        }
      })
    },

    async onFormPutSubmit () {
      return new Promise(resolve => {
        this.state.busy = true
        AgentCallRequestService.put(this.customer).then(({ data }) => {
          this.$bvModal.hide('modal-edit-form')
          this.state.busy = false
          this.$swal
            .fire({
              icon: 'success',
              title: 'Successful',
              text: data.message,
              confirmButtonColor: '#06C270',
              confirmButtonText: 'Dismiss'
            })
            .then(() => {
              if (parseInt(this.customer.is_close) === 1) {
                this.$refs.table.refresh()
              } else {
                const row = _.find(this.$refs.table.localItems, { id: this.customer.call_request_id })
                row.status = data.call_request.status
                row.call_attempt = data.call_request.call_attempt
                row.last_call = data.call_request.last_call
                row.last_status = data.call_request.last_status
                row.last_agent = data.call_request.last_agent
                row.updated_at = data.call_request.updated_at
                row.customer.active = data.customer.active
                row.customer.first_name = data.customer.first_name
                row.customer.last_name = data.customer.last_name
                row.customer.customer_type = data.customer.customer_type
                row.customer.mobile_number = data.customer.mobile_number
                row.customer.email = data.customer.email
                row.customer.birth_date = data.customer.birth_date
                row.customer.gender = data.customer.gender
                row.customer.experience = data.customer.experience
                row.customer.primary_dialect = data.customer.primary_dialect
                row.customer.secondary_dialect = data.customer.secondary_dialect
                row.customer.number_of_household = data.customer.number_of_household
                row.customer.occupation = data.customer.occupation
                row.customer.other_occupation = data.customer.other_occupation
                row.customer.dealers = data.customer.dealers
                row.customer.other_dealer = data.customer.other_dealer
                row.customer.cluster_id = data.customer.cluster_id
                row.customer.cluster = data.customer.cluster
                row.customer.area_id = data.customer.area_id
                row.customer.area = data.customer.area
                row.customer.province_id = data.customer.province_id
                row.customer.province = data.customer.province
                row.customer.municipality_id = data.customer.municipality_id
                row.customer.municipality = data.customer.municipality
                row.customer.barangay_id = data.customer.barangay_id
                row.customer.barangay = data.customer.barangay
                row.customer.farm_address = data.customer.farm_address
                row.customer.mop = data.customer.mop
                row.customer.moc = data.customer.moc
                row.customer.preferred_time = data.customer.preferred_time
                row.customer.raisers = data.customer.raisers
                row.customer.packages = data.customer.packages
                row.customer.crop_details = data.crop_details
                row.customer.details = data.details
                row.customer.crop_histories = data.crop_histories
                row.customer.crop_prices = data.crop_prices
                row.customer.last_call = data.customer.last_call
                row.customer.last_call_status = data.customer.last_call_status
                row.customer.last_agent_name = data.customer.last_agent_name
                row.customer.disposition_type_name = data.customer.disposition_type_name
                row.customer.disposition_name = data.customer.disposition_name
                row.customer.promotion_name = data.customer.promotion_name
                row.customer.unsuccessful_reason_name = data.customer.unsuccessful_reason_name
                row.customer.updated_at = data.customer.updated_at
              }
            })
        })
          .catch(error => {
            this.state.busy = false
            if (error instanceof AxiosError) {
              if (error.code === 422) {
                this.$bvModal.msgBoxOk('Oops! There were problem with your inputs.', {
                  title: 'Validation Error',
                  size: 'sm',
                  buttonSize: 'sm',
                  okVariant: 'danger',
                  centered: true
                })
                this.$refs.form.setErrors(error.message)
                resolve(error)
              }
            }
          })
      })
    },

    onCustomFieldReset () {
      this.customField.field = null
      this.customField.field_value = ''
    },

    async onCustomFieldRefresh () {
      this.getCustomFields()
    },

    async onCustomFieldInsert () {
      this.customer.custom_fields.push({
        id: this.generateId(10),
        field: this.customField.field,
        field_label: _.find(this.list.custom_fields, { value: this.customField.field })?.text || null,
        field_value: this.customField.field_value
      })

      this.onCustomFieldReset()
    },

    async onCustomFieldRemove (current) {
      if (!isNaN(parseInt(current.id))) {
        return
      }

      this.customer.custom_fields = _.remove(this.customer.custom_fields, function (o) {
        return o.id.toString() !== current.id.toString()
      })
    },

    onCropDetailReset () {
      this.cropDetail.id = 0
      this.cropDetail.active = 0
      this.cropDetail.crop = null
      this.cropDetail.crop_name = null
      this.cropDetail.field_size = null
      this.cropDetail.field_size_name = null
      this.cropDetail.number_of_hectares = 0
      this.cropDetail.created_at = null
      this.cropDetail.updated_at = null
    },

    onCropDetailButtonClick () {
      if (this.state.cropDetailEditing) {
        this.onCropDetailUpdate()
        return
      }

      this.onCropDetailInsert()
    },

    onCropDetailInsert () {
      if (!(this.cropDetail.crop && this.cropDetail.field_size && parseFloat(this.cropDetail.number_of_hectares) > 0)) {
        return
      }

      const newId = this.generateId(10)

      const crop = _.find(this.list.crops, { value: this.cropDetail.crop })
      const fieldSize = _.find(this.list.field_sizes, { value: this.cropDetail.field_size })

      this.cropDetail.id = newId
      this.cropDetail.active = 1
      this.cropDetail.crop_name = crop?.text
      this.cropDetail.field_size_name = fieldSize?.text

      this.customer.crop_details.push({
        ...this.cropDetail
      })

      this.onCropDetailReset()
    },

    onCropDetailEdit (current) {
      this.state.cropDetailEditing = true
      this.cropDetail.id = current.id
      this.cropDetail.active = current.active
      this.cropDetail.crop = current.crop
      this.cropDetail.crop_name = current.crop_name
      this.cropDetail.field_size = current.field_size
      this.cropDetail.field_size_name = current.field_size_name
      this.cropDetail.number_of_hectares = current.number_of_hectares
    },

    onCropDetailUpdate () {
      const cropDetail = _.find(this.customer.crop_details, { id: this.cropDetail.id })
      const crop = _.find(this.list.crops, { value: this.cropDetail.crop })
      const fieldSize = _.find(this.list.field_sizes, { value: this.cropDetail.field_size })

      this.cropDetail.crop_name = crop?.text
      this.cropDetail.field_size_name = fieldSize?.text

      if (cropDetail) {
        cropDetail.crop = this.cropDetail.crop
        cropDetail.crop_name = this.cropDetail.crop_name
        cropDetail.field_size = this.cropDetail.field_size
        cropDetail.field_size_name = this.cropDetail.field_size_name
        cropDetail.number_of_hectares = this.cropDetail.number_of_hectares
        // add to dirty list
        if (_.indexOf(this.customer.dirty_crop_details, this.cropDetail.id) < 0) {
          this.customer.dirty_crop_details.push(this.cropDetail.id)
        }
        this.state.cropDetailEditing = false
        this.onCropDetailReset()
      }
    },

    onCropDetailCancelUpdate () {
      this.state.cropDetailEditing = false
      this.onCropDetailReset()
    },

    onCropDetailRemove (current) {
      if (!isNaN(parseInt(current.id))) {
        return
      }

      const cropDetail = _.find(this.customer.crop_details, { id: current.id })
      cropDetail.active = 0
    },

    onCropHistoryReset () {
      this.cropHistory.id = 0
      this.cropHistory.active = 0
      this.selected2.cropping_season = null
      this.selected2.cropping_year = null
      this.selected2.crop = null
      this.selected2.classification = null
      this.cropHistory.crop_name = null
      this.cropHistory.classification_name = null
      this.selected2.varieties = []
      this.selected2.herbicide_ones = []
      this.selected2.herbicide_twos = []
      this.selected2.fungicides = []
      this.selected2.insecticides = []
      this.selected2.fertilizers = []
    },

    onCropHistoryButtonClick () {
      if (this.state.cropHistoryEditing) {
        this.onCropHistoryUpdate()
        return
      }

      this.onCropHistoryInsert()
    },

    onCropHistoryInsert () {
      if (!(this.cropHistory.cropping_season && this.cropHistory.cropping_year && this.cropHistory.crop)) {
        return
      }

      const newId = this.generateId(10)

      const history = _.find(this.customer.crop_histories, {
        cropping_season: this.cropHistory.cropping_season,
        cropping_year: this.cropHistory.cropping_year,
        crop: this.cropHistory.crop
      })

      if (history) {
        this.$bvModal.msgBoxOk('Oops! Crop history already exists.', {
          title: 'Validation Error',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          centered: true
        })
        return
      }

      const crop = _.find(this.list.crops, { value: this.cropHistory.crop })
      const classification = _.find(this.list.classifications, { id: this.cropHistory.classification })

      this.cropHistory.id = newId
      this.cropHistory.active = 1
      this.cropHistory.crop_name = crop?.text
      this.cropHistory.classification_name = classification?.classification_name
      this.cropHistory.variety_names = this.cropHistory.varieties ? this.cropHistory.varieties.map(s => s.variety_name).toString() : null
      this.cropHistory.herbicide_one_names = this.cropHistory.herbicide_ones ? this.cropHistory.herbicide_ones.map(s => s.herbicide_name).toString() : null
      this.cropHistory.herbicide_two_names = this.cropHistory.herbicide_twos ? this.cropHistory.herbicide_twos.map(s => s.herbicide_name).toString() : null
      this.cropHistory.fungicide_names = this.cropHistory.fungicides ? this.cropHistory.fungicides.map(s => s.fungicide_name).toString() : null
      this.cropHistory.insecticide_names = this.cropHistory.insecticides ? this.cropHistory.insecticides.map(s => s.insecticide_name).toString() : null
      this.cropHistory.fertilizer_names = this.cropHistory.fertilizers ? this.cropHistory.fertilizers.map(s => s.fertilizer_name).toString() : null

      this.customer.crop_histories.push({
        ...this.cropHistory
      })

      this.onCropHistoryReset()
    },

    onCropHistoryEdit (current) {
      this.selected2.current = { ...current }
      this.state.cropHistoryEditing = true
      this.cropHistory.id = current.id
      this.cropHistory.active = current.active
      this.selected2.cropping_season = _.find(this.list.cropping_seasons, { value: current.cropping_season })
      this.selected2.cropping_year = _.find(this.list.cropping_years, { value: current.cropping_year })
      this.selected2.crop = _.find(this.list.crops, { value: current.crop })
    },

    onCropHistoryUpdate () {
      const cropHistory = _.find(this.customer.crop_histories, { id: this.cropHistory.id })
      const crop = _.find(this.list.crops, { value: this.cropHistory.crop })
      const classification = _.find(this.list.classifications, { id: this.cropHistory.classification })

      this.cropHistory.crop_name = crop?.text
      this.cropHistory.classification_name = classification?.classification_name

      if (cropHistory) {
        cropHistory.cropping_season = this.cropHistory.cropping_season
        cropHistory.cropping_year = this.cropHistory.cropping_year
        cropHistory.crop = this.cropHistory.crop
        cropHistory.crop_name = this.cropHistory.crop_name
        cropHistory.classification = this.cropHistory.classification
        cropHistory.classification_name = this.cropHistory.classification_name
        cropHistory.varieties = this.cropHistory.varieties
        cropHistory.variety_names = this.cropHistory.varieties ? this.cropHistory.varieties.map(s => s.variety_name).toString() : null
        cropHistory.herbicide_ones = this.cropHistory.herbicide_ones
        cropHistory.herbicide_one_names = this.cropHistory.herbicide_ones ? this.cropHistory.herbicide_ones.map(s => s.herbicide_name).toString() : null
        cropHistory.herbicide_twos = this.cropHistory.herbicide_twos
        cropHistory.herbicide_two_names = this.cropHistory.herbicide_twos ? this.cropHistory.herbicide_twos.map(s => s.herbicide_name).toString() : null
        cropHistory.fungicides = this.cropHistory.fungicides
        cropHistory.fungicide_names = this.cropHistory.fungicides ? this.cropHistory.fungicides.map(s => s.fungicide_name).toString() : null
        cropHistory.insecticides = this.cropHistory.insecticides
        cropHistory.insecticide_names = this.cropHistory.insecticides ? this.cropHistory.insecticides.map(s => s.insecticide_name).toString() : null
        cropHistory.fertilizers = this.cropHistory.fertilizers
        cropHistory.fertilizer_names = this.cropHistory.fertilizers ? this.cropHistory.fertilizers.map(s => s.fertilizer_name).toString() : null
        cropHistory.bag_per_ha = this.cropHistory.bag_per_ha

        // add to dirty list
        if (_.indexOf(this.customer.dirty_crop_histories, this.cropHistory.id) < 0) {
          this.customer.dirty_crop_histories.push(this.cropHistory.id)
        }
        this.state.cropHistoryEditing = false
        this.onCropHistoryReset()
      }
    },

    onCropHistoryCancelUpdate () {
      this.state.cropHistoryEditing = false
      this.onCropHistoryReset()
    },

    onCropHistoryRemove (current) {
      if (!isNaN(parseInt(current.id))) {
        return
      }

      this.customer.crop_histories = _.remove(this.customer.crop_histories, function (o) {
        return o.id.toString() !== current.id.toString()
      })
    },

    onCropPriceReset () {
      this.cropPrice.id = 0
      this.cropPrice.active = 0
      this.cropPrice.cropping_season = null
      this.cropPrice.cropping_year = null
      this.cropPrice.crop = null
      this.cropPrice.total_harvested = 0
      this.cropPrice.selling_condition = null
      this.cropPrice.selling_price = 0
    },

    onCropPriceButtonClick () {
      if (this.state.cropPriceEditing) {
        this.onCropPriceUpdate()
        return
      }

      this.onCropPriceInsert()
    },

    onCropPriceInsert () {
      if (!(this.cropPrice.cropping_season && this.cropPrice.cropping_year && this.cropPrice.crop && this.cropPrice.selling_condition && parseFloat(this.cropPrice.selling_price) > 0)) {
        return
      }

      const newId = this.generateId(10)

      const priceHistory = _.find(this.customer.crop_prices, {
        cropping_season: this.cropPrice.cropping_season,
        cropping_year: this.cropPrice.cropping_year,
        crop: this.cropPrice.crop
      })

      if (priceHistory) {
        this.$bvModal.msgBoxOk('Oops! Price history already exists.', {
          title: 'Validation Error',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          centered: true
        })
        return
      }

      const crop = _.find(this.list.crops, { value: this.cropPrice.crop })

      this.cropPrice.id = newId
      this.cropPrice.active = 1
      this.cropPrice.crop_name = crop?.text

      this.customer.crop_prices.push({
        ...this.cropPrice
      })

      this.onCropPriceReset()
    },

    onCropPriceEdit (current) {
      this.state.cropPriceEditing = true
      this.cropPrice.id = current.id
      this.cropPrice.active = current.active
      this.cropPrice.cropping_season = current.cropping_season
      this.cropPrice.cropping_year = current.cropping_year
      this.cropPrice.crop = current.crop
      this.cropPrice.total_harvested = current.total_harvested
      this.cropPrice.selling_condition = current.selling_condition
      this.cropPrice.selling_price = current.selling_price
    },

    onCropPriceUpdate () {
      const cropPriceHistory = _.find(this.customer.crop_prices, { id: this.cropPrice.id })

      if (cropPriceHistory) {
        cropPriceHistory.cropping_season = this.cropPrice.cropping_season
        cropPriceHistory.cropping_year = this.cropPrice.cropping_year
        cropPriceHistory.crop = this.cropPrice.crop
        cropPriceHistory.total_harvested = this.cropPrice.total_harvested
        cropPriceHistory.selling_condition = this.cropPrice.selling_condition
        cropPriceHistory.selling_price = this.cropPrice.selling_price

        // add to dirty list
        if (_.indexOf(this.customer.dirty_crop_prices, this.cropPrice.id) < 0) {
          this.customer.dirty_crop_prices.push(this.cropPrice.id)
        }
        this.state.cropPriceEditing = false
        this.onCropPriceReset()
      }
    },

    onCropPriceCancelUpdate () {
      this.state.cropPriceEditing = false
      this.onCropPriceReset()
    },

    onCropPriceRemove (current) {
      if (!isNaN(parseInt(current.id))) {
        return
      }

      this.customer.crop_prices = _.remove(this.customer.crop_prices, function (o) {
        return o.id.toString() !== current.id.toString()
      })
    },

    onViewCommunicationLogDetail (current) {
      this.selected.log = { ...current }
      this.$bvModal.show('modal-view-communication-log-detail')
    },

    onViewCallRequestLog (current) {
      this.selected.log = { ...current }
      this.$bvModal.show('modal-view-call-request-log')
    },

    onFilterChanged () {
      this.$refs.table.refresh()
    }
  }
}
</script>
<style>
#modal-preview-log-attachment .modal-xl {
  width:100vw;
  max-width: none;
  min-height: 100vh;
  margin: 0
}
#modal-preview-log-attachment .modal-content {
  min-height: 100vh;
}
</style>
